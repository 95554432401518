import React from 'react';
import './PaginasMP.css';

const Success = () => {
  return (
    <div className="success">
      <h1>Pago Exitoso</h1>
      <p>Gracias por tu compra. Hemos recibido tu pago exitosamente.</p>
      <p>Pronto recibirás novedades.</p>
    </div>
  );
};

export default Success;
