import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import './ContactForm.css';
import Spinner from './Spinner';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    const handleReCaptchaVerify = async () => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }

      setIsLoading(true);
      try {
        const token = await executeRecaptcha('submit_contact');
        setRecaptchaToken(token);
      } catch (error) {
        console.error('Error executing reCAPTCHA', error);
      } finally {
        setIsLoading(false);
      }
    };

    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaToken) {
      alert('Error de reCAPTCHA, por favor intente nuevamente.');
      return;
    }

    setIsLoading(true);

    const contactData = {
      name,
      email,
      message,
      timestamp: new Date(),
      recaptchaToken,
      isArchived: false,
    };

    try {
      const response = await fetch('https://us-central1-priball.cloudfunctions.net/verifyReCaptcha', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: recaptchaToken }),
      });

      const result = await response.json();

      if (result.success) {
        await addDoc(collection(db, 'contacts'), contactData);
        console.log('Form submitted', { name, email, message, isArchived: false });
        setName('');
        setEmail('');
        setMessage('');
        alert('Mensaje enviado correctamente.');
      } else {
        alert('Error de reCAPTCHA, por favor intente nuevamente.');
      }
    } catch (error) {
      console.error('Error al enviar el mensaje: ', error);
      alert('Error al enviar el mensaje, por favor intente nuevamente.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="contact-form">
      <h2>Contacto</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Nombre</label>
          <input 
            type="text" 
            id="name" 
            value={name} 
            onChange={(e) => setName(e.target.value)} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input 
            type="email" 
            id="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Mensaje</label>
          <textarea 
            id="message" 
            value={message} 
            onChange={(e) => setMessage(e.target.value)} 
            required 
          ></textarea>
        </div>
        <button type="submit">Enviar</button>
      </form>
    </div>
  );
};

export default ContactForm;