import React, { useState, useCallback } from 'react';
import { db } from '../firebase';
import { collection, getDocs, query, orderBy, where } from 'firebase/firestore';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import './SalesReport.css';
import Spinner from './Spinner';
import Notification from './Notification';

const SalesReport = () => {
  const [orderSales, setOrderSales] = useState([]);
  const [mercadoPagoSales, setMercadoPagoSales] = useState([]);
  const [physicalSales, setPhysicalSales] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({ message: '', show: false });
  const [showOrderSummary, setShowOrderSummary] = useState(false);
  const [showOrderProducts, setShowOrderProducts] = useState(false);
  const [showMercadoPagoSummary, setShowMercadoPagoSummary] = useState(false);
  const [showMercadoPagoProducts, setShowMercadoPagoProducts] = useState(false);
  const [showPhysicalSalesSummary, setShowPhysicalSalesSummary] = useState(false);
  const [showPhysicalSalesProducts, setShowPhysicalSalesProducts] = useState(false);
  const [showCombinedProducts, setShowCombinedProducts] = useState(false);
  const [showTotalSales, setShowTotalSales] = useState(false);
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().substring(0, 10));
  const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().substring(0, 10));

  const fetchSalesData = useCallback(async () => {
    setLoading(true);
    try {
      const fetchOrders = async () => {
        const ordersCollection = collection(db, 'pedidos');
        let ordersQuery = query(ordersCollection, orderBy('date', 'desc'));

        if (startDate && endDate) {
          const start = new Date(startDate);
          start.setUTCHours(0, 0, 0, 0);
          const end = new Date(endDate);
          end.setUTCHours(23, 59, 59, 999);
          ordersQuery = query(ordersCollection, orderBy('date', 'desc'), where('date', '>=', start), where('date', '<=', end));
        }

        const ordersSnapshot = await getDocs(ordersQuery);
        return ordersSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            orderNumber: data.orderNumber,
            customerName: data.nombre,
            address: `${data.domicilio}, ${data.localidad}, ${data.codigoPostal}, ${data.provincia}, ${data.pais}`,
            email: data.email,
            phone: data.telefono,
            ...data,
            date: data.date?.toDate(),
            cartItems: data.cartItems?.map(item => ({
              ...item,
              image: item.images && item.images[0] ? item.images[0] : '',
              costPrice: item.costPrice,
              price: item.price
            })),
            isConfirmed: data.isConfirmed || false
          };
        }).filter(order => order.isConfirmed);
      };

      const fetchMercadoPago = async () => {
        const mercadoPagoCollection = collection(db, 'compras_mercadopago');
        let mercadoPagoQuery = query(mercadoPagoCollection, orderBy('date', 'desc'));

        if (startDate && endDate) {
          const start = new Date(startDate);
          start.setUTCHours(0, 0, 0, 0);
          const end = new Date(endDate);
          end.setUTCHours(23, 59, 59, 999);
          mercadoPagoQuery = query(mercadoPagoCollection, orderBy('date', 'desc'), where('date', '>=', start), where('date', '<=', end));
        }

        const mercadoPagoSnapshot = await getDocs(mercadoPagoQuery);
        return mercadoPagoSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            orderNumber: data.orderNumber,
            customerName: data.nombre,
            address: `${data.domicilio}, ${data.localidad}, ${data.codigoPostal}, ${data.provincia}, ${data.pais}`,
            email: data.email,
            phone: data.telefono,
            ...data,
            date: data.date?.toDate(),
            cartItems: data.cartItems?.map(item => ({
              ...item,
              image: item.images && item.images[0] ? item.images[0] : '',
              costPrice: item.costPrice || 0,
              price: item.price || 0,
              quantity: item.quantity || 0
            })),
            isConfirmed: data.isConfirmed || false
          };
        }).filter(sale => sale.isConfirmed);
      };

      const fetchPhysicalSales = async () => {
        const physicalSalesCollection = collection(db, 'ventas_fisicas');
        let physicalSalesQuery = query(physicalSalesCollection, orderBy('date', 'desc'), where('isConfirmed', '==', true));

        if (startDate && endDate) {
          const start = new Date(startDate);
          start.setUTCHours(0, 0, 0, 0);
          const end = new Date(endDate);
          end.setUTCHours(23, 59, 59, 999);
          physicalSalesQuery = query(physicalSalesCollection, orderBy('date', 'desc'), where('date', '>=', start), where('date', '<=', end), where('isConfirmed', '==', true));
        }

        const physicalSalesSnapshot = await getDocs(physicalSalesQuery);
        return physicalSalesSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            orderNumber: data.orderNumber,
            customerName: data.customerData?.name,
            address: `${data.customerData?.cuit}, ${data.customerData?.invoiceType}`,
            email: '',
            phone: '',
            ...data,
            date: data.date?.toDate(),
            cartItems: data.products?.map(item => ({
              ...item,
              image: item.images && item.images[0] ? item.images[0] : '',
              costPrice: item.costPrice || 0,
              price: item.price || 0,
              quantity: item.quantity || 0
            })),
            isConfirmed: true
          };
        });
      };

      const [ordersList, mercadoPagoList, physicalSalesList] = await Promise.all([fetchOrders(), fetchMercadoPago(), fetchPhysicalSales()]);

      setOrderSales(ordersList);
      setMercadoPagoSales(mercadoPagoList);
      setPhysicalSales(physicalSalesList);
    } catch (error) {
      console.error("Error fetching sales data:", error);
      setNotification({ message: 'Error al cargar datos de ventas.', show: true });
    }
    setLoading(false);
  }, [startDate, endDate]);

  const formatCurrency = (value) => {
    return value.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' });
  };

  const calculateTotalRevenue = (sales) => {
    const totalRevenue = sales.reduce((total, sale) => total + (parseFloat(sale.totalPrice) || 0), 0);
    return totalRevenue.toFixed(2);
  };

  const calculateTotalCost = (sales) => {
    const totalCost = sales.reduce((total, sale) => {
      const cartTotal = sale.cartItems?.reduce((itemTotal, item) => itemTotal + ((parseFloat(item.costPrice) || 0) * (parseInt(item.quantity) || 0)), 0) || 0;
      return total + cartTotal;
    }, 0);
    return totalCost.toFixed(2);
  };

  const calculateTotalProfit = (revenue, cost) => {
    const profit = parseFloat(revenue) - parseFloat(cost);
    return profit.toFixed(2);
  };

  const calculateTotalProducts = (sales) => {
    const productTotals = {};
    sales.forEach(sale => {
      sale.cartItems?.forEach(item => {
        if (!productTotals[item.model]) {
          productTotals[item.model] = { quantity: 0, cost: 0, unitCost: parseFloat(item.costPrice) || 0 };
        }
        productTotals[item.model].quantity += parseInt(item.quantity) || 0;
        productTotals[item.model].cost += (parseFloat(item.costPrice) || 0) * (parseInt(item.quantity) || 0);
      });
    });
    return productTotals;
  };

  const printProductSummary = (products) => {
    const productList = Object.entries(products)
      .map(([model, { quantity, cost, unitCost }]) => `${model}: ${quantity} unidades - Precio por Unidad: ${formatCurrency(unitCost)} - Precio Total: ${formatCurrency(cost)}`)
      .join('\n');

    const totalCost = Object.values(products)
      .reduce((total, { cost }) => total + cost, 0);

    const printWindow = window.open('', '', 'height=400,width=600');
    printWindow.document.write('<html><head><title>Resumen de Productos</title></head><body>');
    printWindow.document.write('<pre>' + productList + '\n\nTotal Precio de Costo: ' + formatCurrency(totalCost) + '</pre>');
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  const filteredOrderSales = orderSales.filter(sale =>
    sale.date >= new Date(startDate).setUTCHours(0, 0, 0, 0) && sale.date <= new Date(endDate).setUTCHours(23, 59, 59, 999)
  );

  const filteredMercadoPagoSales = mercadoPagoSales.filter(sale =>
    sale.date >= new Date(startDate).setUTCHours(0, 0, 0, 0) && sale.date <= new Date(endDate).setUTCHours(23, 59, 59, 999)
  );

  const filteredPhysicalSales = physicalSales.filter(sale =>
    sale.date >= new Date(startDate).setUTCHours(0, 0, 0, 0) && sale.date <= new Date(endDate).setUTCHours(23, 59, 59, 999)
  );

  const totalOrderProducts = calculateTotalProducts(filteredOrderSales);
  const totalMercadoPagoProducts = calculateTotalProducts(filteredMercadoPagoSales);
  const totalPhysicalProducts = calculateTotalProducts(filteredPhysicalSales);

  const getPieData = (total, cost, profit) => {
    return {
      labels: ['Precio de Venta', 'Precio de Costo', 'Ganancia'],
      datasets: [
        {
          data: [parseFloat(total), parseFloat(cost), parseFloat(profit)],
          backgroundColor: ['#FFCE56', '#FF6384', '#36A2EB'],
        },
      ],
    };
  };

  const totalOrderRevenue = calculateTotalRevenue(filteredOrderSales);
  const totalOrderCost = calculateTotalCost(filteredOrderSales);
  const totalOrderProfit = calculateTotalProfit(totalOrderRevenue, totalOrderCost);
  const orderPieData = getPieData(totalOrderRevenue, totalOrderCost, totalOrderProfit);

  const totalMercadoPagoRevenue = calculateTotalRevenue(filteredMercadoPagoSales);
  const totalMercadoPagoCost = calculateTotalCost(filteredMercadoPagoSales);
  const totalMercadoPagoProfit = calculateTotalProfit(totalMercadoPagoRevenue, totalMercadoPagoCost);
  const mercadoPagoPieData = getPieData(totalMercadoPagoRevenue, totalMercadoPagoCost, totalMercadoPagoProfit);

  const totalPhysicalRevenue = calculateTotalRevenue(filteredPhysicalSales);
  const totalPhysicalCost = calculateTotalCost(filteredPhysicalSales);
  const totalPhysicalProfit = calculateTotalProfit(totalPhysicalRevenue, totalPhysicalCost);
  const physicalSalesPieData = getPieData(totalPhysicalRevenue, totalPhysicalCost, totalPhysicalProfit);

  // Datos para el gráfico combinado
  const combinedRevenuePieData = {
    labels: ['Pedidos', 'MercadoPago', 'Ventas Físicas'],
    datasets: [
      {
        data: [parseFloat(totalOrderRevenue), parseFloat(totalMercadoPagoRevenue), parseFloat(totalPhysicalRevenue)],
        backgroundColor: ['#ccc', '#45aaed', '#444'],
      },
    ],
  };

  const combinedProfitPieData = {
    labels: ['Pedidos', 'MercadoPago', 'Ventas Físicas'],
    datasets: [
      {
        data: [parseFloat(totalOrderProfit), parseFloat(totalMercadoPagoProfit), parseFloat(totalPhysicalProfit)],
        backgroundColor: ['#ccc', '#45aaed', '#444'],
      },
    ],
  };

  // Datos para el gráfico de productos combinados
  const combinedProductsData = {
    labels: Object.keys({ ...totalOrderProducts, ...totalMercadoPagoProducts, ...totalPhysicalProducts }),
    datasets: [
      {
        data: Object.values({ ...totalOrderProducts, ...totalMercadoPagoProducts, ...totalPhysicalProducts }).map(item => item.quantity),
        backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40']
      }
    ]
  };

  return (
    <div className="sales-report">
      <h2>Informe de Ventas</h2>
      <Notification message={notification.message} show={notification.show} />
      <div className="filters">
        <input
          type="date"
          placeholder="Fecha de inicio"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="filter-input"
        />
        <input
          type="date"
          placeholder="Fecha de fin"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="filter-input"
        />
        <button onClick={fetchSalesData} className="apply-button">Aplicar</button>
      </div>

      {loading ? (
        <Spinner />
      ) : (
        <div className="sales-summary">
          <div className="sales-section">
            <h3>Ventas y Ganancias por canal</h3>
            <div className="button-group">
              <button
                onClick={() => setShowTotalSales(!showTotalSales)}
                className={`view-toggle-btn ${showTotalSales ? 'active' : ''}`}
              >
                {showTotalSales ? 'Ocultar Resumen' : 'Mostrar Resumen'}
              </button>
            </div>
            {showTotalSales && (
              <div className="summary-container">
                <div className="chart-container half-size">
                  <h4>Ventas por canal</h4>
                  <Pie data={combinedRevenuePieData} />
                </div>
                <div className="chart-container half-size">
                  <h4>Ganancias por canal</h4>
                  <Pie data={combinedProfitPieData} />
                </div>
              </div>
            )}
          </div>

          <div className="sales-section">
            <h3>Ventas por Pedido</h3>
            <div className="button-group">
              <button
                onClick={() => setShowOrderSummary(!showOrderSummary)}
                className={`view-toggle-btn ${showOrderSummary ? 'active' : ''}`}
              >
                {showOrderSummary ? 'Ocultar Resumen' : 'Mostrar Resumen'}
              </button>
              <button
                onClick={() => setShowOrderProducts(!showOrderProducts)}
                className={`view-toggle-btn ${showOrderProducts ? 'active' : ''}`}
              >
                {showOrderProducts ? 'Ocultar Productos' : 'Mostrar Productos'}
              </button>
            </div>
            {showOrderSummary && (
              <div className="summary-container">
                <div className="totals">
                  <h4>Total vendido por Pedidos: {formatCurrency(totalOrderRevenue)}</h4>
                  <h4>Total al precio de Costo: {formatCurrency(totalOrderCost)}</h4>
                  <h4>Total ganancia por pedidos: {formatCurrency(totalOrderProfit)}</h4>
                </div>
                <div className="chart-container half-size">
                  <Pie data={orderPieData} />
                </div>
              </div>
            )}
            {showOrderProducts && (
              <div className="summary-container">
                <div className="chart-container half-size">
                  <Pie data={{
                    labels: Object.keys(totalOrderProducts),
                    datasets: [{
                      data: Object.values(totalOrderProducts).map(item => item.quantity),
                      backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40']
                    }]
                  }} />
                </div>
                <div className="product-summary">
                  <h4>Productos Vendidos por Pedido</h4>
                  <ul className="product-list">
                    {Object.entries(totalOrderProducts).map(([model, { quantity, cost, unitCost }]) => (
                      <li key={model}>
                        <span className="product-model">{model}:</span>
                        <span className="product-quantity">{quantity} unidades</span>
                        <span className="product-unit-cost"> - Precio por Unidad: {formatCurrency(unitCost)}</span>
                        <span className="product-cost"> - Precio Total: {formatCurrency(cost)}</span>
                      </li>
                    ))}
                  </ul>
                  <div className="total-cost">
                    <h4>Total Precio de Costo: {formatCurrency(Object.values(totalOrderProducts).reduce((total, { cost }) => total + cost, 0))}</h4>
                  </div>
                  <button onClick={() => printProductSummary(totalOrderProducts)} className="print-button">Imprimir Resumen de Productos</button>
                </div>
              </div>
            )}
          </div>

          <div className="sales-section">
            <h3>Ventas con MercadoPago</h3>
            <div className="button-group">
              <button
                onClick={() => setShowMercadoPagoSummary(!showMercadoPagoSummary)}
                className={`view-toggle-btn ${showMercadoPagoSummary ? 'active' : ''}`}
              >
                {showMercadoPagoSummary ? 'Ocultar Resumen' : 'Mostrar Resumen'}
              </button>
              <button
                onClick={() => setShowMercadoPagoProducts(!showMercadoPagoProducts)}
                className={`view-toggle-btn ${showMercadoPagoProducts ? 'active' : ''}`}
              >
                {showMercadoPagoProducts ? 'Ocultar Productos' : 'Mostrar Productos'}
              </button>
            </div>
            {showMercadoPagoSummary && (
              <div className="summary-container">
                <div className="totals">
                  <h4>Total vendido por MercadoPago: {formatCurrency(totalMercadoPagoRevenue)}</h4>
                  <h4>Total al precio de costo: {formatCurrency(totalMercadoPagoCost)}</h4>
                  <h4>Total ganancia por MercadoPago: {formatCurrency(totalMercadoPagoProfit)}</h4>
                </div>
                <div className="chart-container half-size">
                  <Pie data={mercadoPagoPieData} />
                </div>
              </div>
            )}
            {showMercadoPagoProducts && (
              <div className="summary-container">
                <div className="chart-container half-size">
                  <Pie data={{
                    labels: Object.keys(totalMercadoPagoProducts),
                    datasets: [{
                      data: Object.values(totalMercadoPagoProducts).map(item => item.quantity),
                      backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40']
                    }]
                  }} />
                </div>
                <div className="product-summary">
                  <h4>Productos Vendidos por MercadoPago</h4>
                  <ul className="product-list">
                    {Object.entries(totalMercadoPagoProducts).map(([model, { quantity, cost, unitCost }]) => (
                      <li key={model}>
                        <span className="product-model">{model}:</span>
                        <span className="product-quantity">{quantity} unidades</span>
                        <span className="product-unit-cost"> - Precio por Unidad: {formatCurrency(unitCost)}</span>
                        <span className="product-cost"> - Precio Total: {formatCurrency(cost)}</span>
                      </li>
                    ))}
                  </ul>
                  <div className="total-cost">
                    <h4>Total Precio de Costo: {formatCurrency(Object.values(totalMercadoPagoProducts).reduce((total, { cost }) => total + cost, 0))}</h4>
                  </div>
                  <button onClick={() => printProductSummary(totalMercadoPagoProducts)} className="print-button">Imprimir Resumen de Productos</button>
                </div>
              </div>
            )}
          </div>

          <div className="sales-section">
            <h3>Ventas Físicas</h3>
            <div className="button-group">
              <button
                onClick={() => setShowPhysicalSalesSummary(!showPhysicalSalesSummary)}
                className={`view-toggle-btn ${showPhysicalSalesSummary ? 'active' : ''}`}
              >
                {showPhysicalSalesSummary ? 'Ocultar Resumen' : 'Mostrar Resumen'}
              </button>
              <button
                onClick={() => setShowPhysicalSalesProducts(!showPhysicalSalesProducts)}
                className={`view-toggle-btn ${showPhysicalSalesProducts ? 'active' : ''}`}
              >
                {showPhysicalSalesProducts ? 'Ocultar Productos' : 'Mostrar Productos'}
              </button>
            </div>
            {showPhysicalSalesSummary && (
              <div className="summary-container">
                <div className="totals">
                  <h4>Total vendido por Ventas Físicas: {formatCurrency(totalPhysicalRevenue)}</h4>
                  <h4>Total al precio de Costo: {formatCurrency(totalPhysicalCost)}</h4>
                  <h4>Total ganancia por Ventas Físicas: {formatCurrency(totalPhysicalProfit)}</h4>
                </div>
                <div className="chart-container half-size">
                  <Pie data={physicalSalesPieData} />
                </div>
              </div>
            )}
            {showPhysicalSalesProducts && (
              <div className="summary-container">
                <div className="chart-container half-size">
                  <Pie data={{
                    labels: Object.keys(totalPhysicalProducts),
                    datasets: [{
                      data: Object.values(totalPhysicalProducts).map(item => item.quantity),
                      backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40']
                    }]
                  }} />
                </div>
                <div className="product-summary">
                  <h4>Productos Vendidos por Ventas Físicas</h4>
                  <ul className="product-list">
                    {Object.entries(totalPhysicalProducts).map(([model, { quantity, cost, unitCost }]) => (
                      <li key={model}>
                        <span className="product-model">{model}:</span>
                        <span className="product-quantity">{quantity} unidades</span>
                        <span className="product-unit-cost"> - Precio por Unidad: {formatCurrency(unitCost)}</span>
                        <span className="product-cost"> - Precio Total: {formatCurrency(cost)}</span>
                      </li>
                    ))}
                  </ul>
                  <div className="total-cost">
                    <h4>Total Precio de Costo: {formatCurrency(Object.values(totalPhysicalProducts).reduce((total, { cost }) => total + cost, 0))}</h4>
                  </div>
                  <button onClick={() => printProductSummary(totalPhysicalProducts)} className="print-button">Imprimir Resumen de Productos</button>
                </div>
              </div>
            )}
          </div>

          <div className="sales-section">
            <h3>Productos Vendidos en Todos los Canales</h3>
            <div className="button-group">
              <button
                onClick={() => setShowCombinedProducts(!showCombinedProducts)}
                className={`view-toggle-btn ${showCombinedProducts ? 'active' : ''}`}
              >
                {showCombinedProducts ? 'Ocultar Resumen' : 'Mostrar Resumen'}
              </button>
            </div>
            {showCombinedProducts && (
              <div className="summary-container">
                <div className="chart-container half-size">
                  <Pie data={combinedProductsData} />
                </div>
                <div className="product-summary">
                  <h4>Productos Vendidos en Todos los Canales</h4>
                  <ul className="product-list">
                    {Object.entries({ ...totalOrderProducts, ...totalMercadoPagoProducts, ...totalPhysicalProducts }).map(([model, { quantity, cost, unitCost }]) => (
                      <li key={model}>
                        <span className="product-model">{model}:</span>
                        <span className="product-quantity">{quantity} unidades</span>
                        <span className="product-unit-cost"> - Precio por Unidad: {formatCurrency(unitCost)}</span>
                        <span className="product-cost"> - Precio Total: {formatCurrency(cost)}</span>
                      </li>
                    ))}
                  </ul>
                  <div className="total-cost">
                    <h4>Total Precio de Costo: {formatCurrency(Object.values({ ...totalOrderProducts, ...totalMercadoPagoProducts, ...totalPhysicalProducts }).reduce((total, { cost }) => total + cost, 0))}</h4>
                  </div>
                  <button onClick={() => printProductSummary({ ...totalOrderProducts, ...totalMercadoPagoProducts, ...totalPhysicalProducts })} className="print-button">Imprimir Resumen de Productos</button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SalesReport;
