import React, { useState, useEffect } from 'react';
import logo from '../Logos/escudoPriballUniverso.png';
import instagramLogo from '../Logos/logoInstragram.png';
import facebookLogo from '../Logos/logofacebook.png';
import whatsappLogo from '../Logos/logowhatsApp.png';
import './Home.css';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Spinner from './Spinner';
import { Link } from 'react-router-dom';
import { pinFileToIPFS } from '../pinata';
import Subscription from './Subscription';

const Home = () => {
  const [content, setContent] = useState({
    images: [],
    instagram: '',
    facebook: '',
    whatsapp: ''
  });
  const [imagePreviews, setImagePreviews] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [copySuccess, setCopySuccess] = useState('');

  useEffect(() => {
    const isAdminUser = localStorage.getItem('isAdmin') === 'true';
    setIsAdmin(isAdminUser);

    const fetchHomeInfo = async () => {
      setIsLoading(true);
      try {
        const docRef = doc(db, 'HomeInfo', 'main');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setContent(data);
          setImagePreviews(data.images);
        }
      } catch (error) {
        console.error('Error fetching home info:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchHomeInfo();
  }, []);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href)
      .then(() => {
        setCopySuccess('¡Enlace copiado con éxito!');
      })
      .catch((err) => {
        console.error('Error al copiar el enlace:', err);
      });
  };

  const handleImageUpload = async (index, e) => {
    const file = e.target.files[0];
    if (file) {
      setIsLoading(true);
      try {
        const imageUrl = await pinFileToIPFS(file);
        const newImages = [...content.images];
        newImages[index] = imageUrl;
        setContent({ ...content, images: newImages });
        const newImagePreviews = [...imagePreviews];
        newImagePreviews[index] = URL.createObjectURL(file);
        setImagePreviews(newImagePreviews);
      } catch (error) {
        console.error('Error uploading image:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleWhatsAppClick = () => {
    const whatsappUrl = `https://wa.me/${content.whatsapp}`;
    window.open(whatsappUrl, '_blank');
  };

  const handleAddImage = () => {
    setContent({ ...content, images: [...content.images, ''] });
    setImagePreviews([...imagePreviews, '']);
  };

  const handleRemoveImage = (index) => {
    const newImages = content.images.filter((_, i) => i !== index);
    const newImagePreviews = imagePreviews.filter((_, i) => i !== index);
    setContent({ ...content, images: newImages });
    setImagePreviews(newImagePreviews);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContent({ ...content, [name]: value });
  };

  const handleSaveChanges = async () => {
    setIsLoading(true);
    try {
      await setDoc(doc(db, 'HomeInfo', 'main'), content);
      alert('Cambios guardados correctamente.');
    } catch (error) {
      console.error('Error saving changes:', error);
      alert('Error al guardar los cambios, por favor intente nuevamente.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="home">
      <div className="home-logo-container">
        <Link to="/productos">
          <img src={logo} alt="Logo" className="home-logo" />
        </Link>
      </div>
      <div className="home-images">
        {content.images.map((image, index) => (
          <div key={index} className="home-image-item">
            {isAdmin && (
              <button className="remove-button" onClick={() => handleRemoveImage(index)}>Eliminar</button>
            )}
            {isAdmin && !image && (
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageUpload(index, e)}
              />
            )}
            {image && (
              <img
                src={imagePreviews[index] || image}
                alt="Home"
                className="home-image"
              />
            )}
          </div>
        ))}
      </div>
      {isAdmin && (
        <button onClick={handleAddImage} className="add-image-button">Agregar Imagen</button>
      )}
      <div className="home-social">
        {isAdmin ? (
          <>
            <input
              type="text"
              name="instagram"
              value={content.instagram}
              onChange={handleInputChange}
              placeholder="Instagram URL"
              className="social-input"
            />
            <input
              type="text"
              name="facebook"
              value={content.facebook}
              onChange={handleInputChange}
              placeholder="Facebook URL"
              className="social-input"
            />
            <input
              type="text"
              name="whatsapp"
              value={content.whatsapp}
              onChange={handleInputChange}
              placeholder="WhatsApp número (con código de país)"
              className="social-input"
            />
            <button className="save-button" onClick={handleSaveChanges}>Guardar cambios</button>
          </>
        ) : (
          <div className="social-links">
            <a href={content.instagram} target="_blank" rel="noopener noreferrer">
              <img src={instagramLogo} alt="Instagram" className="social-logo" />
            </a>
            <a href={content.facebook} target="_blank" rel="noopener noreferrer">
              <img src={facebookLogo} alt="Facebook" className="social-logo" />
            </a>
            <img 
              src={whatsappLogo} 
              alt="WhatsApp" 
              className="social-logo" 
              onClick={handleWhatsAppClick}
              style={{ cursor: 'pointer' }}
            />
          </div>
        )}
      </div>
      <Subscription />
      <div className="share-app-container">
        <button onClick={handleCopyLink} className="share-button">
          Compartir App
        </button>
        {copySuccess && <p className="copy-success">{copySuccess}</p>}
      </div>
    </div>
  );
};

export default Home;
