import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';
import './DiscountConfig.css';

import Notification from './Notification';
import Spinner from './Spinner';

const DiscountConfig = () => {
  const [discounts, setDiscounts] = useState([]);
  const [discountData, setDiscountData] = useState({
    type: '',
    keyword: '',
    minQuantity: '',
    minAmount: '',
    percentage: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({ message: '', show: false });

  useEffect(() => {
    const fetchDiscounts = async () => {
      setIsLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, 'discounts'));
        const fetchedDiscounts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setDiscounts(fetchedDiscounts);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        showNotification('Error al cargar los descuentos.');
        console.error("Error fetching discounts: ", error);
      }
    };

    fetchDiscounts();
  }, []);

  const showNotification = (message) => {
    setNotification({ message, show: true });
    setTimeout(() => setNotification({ message: '', show: false }), 3000);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDiscountData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddDiscount = async () => {
    const { type, keyword, minQuantity, minAmount, percentage } = discountData;

    if (
      percentage === '' || 
      (type === 'keyword' && keyword === '') || 
      (type === 'quantity' && minQuantity === '') || 
      (type === 'amount' && minAmount === '')
    ) {
      showNotification('Por favor complete todos los campos.');
      return;
    }

    setIsLoading(true);
    try {
      let newDiscount = { type, percentage: Number(percentage) };

      if (type === 'keyword') {
        newDiscount.keyword = keyword;
      } else if (type === 'quantity') {
        newDiscount.minQuantity = Number(minQuantity);
      } else if (type === 'amount') {
        newDiscount.minAmount = Number(minAmount);
      }

      await addDoc(collection(db, 'discounts'), newDiscount);
      setDiscounts([...discounts, newDiscount]);

      setDiscountData({
        type: '',
        keyword: '',
        minQuantity: '',
        minAmount: '',
        percentage: '',
      });

      setIsLoading(false);
      showNotification('Descuento agregado exitosamente.');
    } catch (error) {
      setIsLoading(false);
      showNotification('Error al agregar el descuento.');
      console.error("Error adding discount: ", error);
    }
  };

  const handleDeleteDiscount = async (id) => {
    setIsLoading(true);
    try {
      await deleteDoc(doc(db, 'discounts', id));
      setDiscounts(discounts.filter(discount => discount.id !== id));
      setIsLoading(false);
      showNotification('Descuento eliminado exitosamente.');
    } catch (error) {
      setIsLoading(false);
      showNotification('Error al eliminar el descuento.');
      console.error("Error deleting discount: ", error);
    }
  };

  return (
    <div className="discount-config">
      <Notification message={notification.message} show={notification.show} />
      {isLoading && <Spinner />}
      <h2 className="discount-config-title">Configuración de Descuentos</h2>
      
      <div className="discount-form">
        <select
          name="type"
          value={discountData.type}
          onChange={handleInputChange}
          className="discount-input"
        >
          <option value="">Seleccionar Tipo de Descuento</option>
          <option value="keyword">Palabra Clave</option>
          <option value="quantity">Cantidad Mínima</option>
          <option value="amount">Monto Mínimo</option>
        </select>

        {discountData.type === 'keyword' && (
          <input
            type="text"
            name="keyword"
            placeholder="Palabra clave"
            value={discountData.keyword}
            onChange={handleInputChange}
            className="discount-input"
          />
        )}

        {discountData.type === 'quantity' && (
          <input
            type="number"
            name="minQuantity"
            placeholder="Cantidad mínima"
            value={discountData.minQuantity}
            onChange={handleInputChange}
            className="discount-input"
          />
        )}

        {discountData.type === 'amount' && (
          <input
            type="number"
            name="minAmount"
            placeholder="Monto mínimo"
            value={discountData.minAmount}
            onChange={handleInputChange}
            className="discount-input"
          />
        )}

        <input
          type="number"
          name="percentage"
          placeholder="Porcentaje de descuento"
          value={discountData.percentage}
          onChange={handleInputChange}
          className="discount-input"
        />
        
        <button onClick={handleAddDiscount} className="discount-button">
          Agregar Descuento
        </button>
      </div>

      <div className="discount-list">
        <h3 className="discount-list-title">Descuentos Actuales</h3>
        {discounts.map(discount => (
          <div key={discount.id} className="discount-item">
            {discount.type === 'keyword' && (
              <span className="discount-detail">Palabra clave: {discount.keyword}</span>
            )}
            {discount.type === 'quantity' && (
              <span className="discount-detail">Cantidad mínima: {discount.minQuantity}</span>
            )}
            {discount.type === 'amount' && (
              <span className="discount-detail">Monto mínimo: {discount.minAmount}</span>
            )}
            <span className="discount-detail">Porcentaje: {discount.percentage}%</span>
            <button onClick={() => handleDeleteDiscount(discount.id)} className="discount-delete-button">
              Eliminar
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DiscountConfig;