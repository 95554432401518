import React, { useEffect, useState, useCallback, useRef } from 'react';
import { db, auth } from '../firebase';
import { collection, getDocs, doc, updateDoc, addDoc, getDoc } from 'firebase/firestore';
import { uploadImage } from '../firebase';
import './MassEditor.css';
import Spinner from './Spinner';
import Notification from './Notification';

const MassEditor = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState({ message: '', show: false });
  const [newCategory, setNewCategory] = useState('');
  const [newProduct, setNewProduct] = useState({
    model: '',
    stock: '',
    costPrice: '',
    price: '',
    category: '',
    description: '',
    images: []
  });
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const textareaRef = useRef(null);

  const fetchProducts = useCallback(async () => {
    try {
      const productsCollection = await getDocs(collection(db, 'products'));
      let productsData = productsCollection.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      
      // Ordenar productos alfabéticamente por 'model'
      productsData = productsData.sort((a, b) => a.model.localeCompare(b.model));
  
      setProducts(productsData);
  
      // Extraer categorías únicas y ordenarlas alfabéticamente
      const uniqueCategories = [...new Set(productsData.map(product => product.category))].sort((a, b) => a.localeCompare(b));
      setCategories(uniqueCategories);
    } catch (error) {
      console.error("Error fetching products:", error);
      setNotification({ message: 'Error al cargar productos.', show: true });
    } finally {
      setLoading(false);
    }
  }, []);
  

  const checkAuthStatus = useCallback(async () => {
    const user = auth.currentUser;
    if (user) {
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists && userDoc.data().role === 'admin') {
        setIsAdmin(true);
      }
    }
  }, []);

  useEffect(() => {
    fetchProducts();
    checkAuthStatus();
  }, [fetchProducts, checkAuthStatus]);

  const handleInputChange = (id, field, value) => {
    setProducts(products.map(product =>
      product.id === id ? { ...product, [field]: value } : product
    ));
  };

  const handleNewProductChange = (field, value) => {
    setNewProduct({ ...newProduct, [field]: value });
  };

  const handleImageUpload = async (id, files) => {
    try {
      const uploadPromises = Array.from(files).map(file => uploadImage(file));
      const imageUrls = await Promise.all(uploadPromises);
      if (id) {
        const updatedProducts = products.map(product =>
          product.id === id ? { ...product, images: [...product.images, ...imageUrls] } : product
        );
        setProducts(updatedProducts);
      } else {
        setNewProduct({ ...newProduct, images: [...newProduct.images, ...imageUrls] });
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      setNotification({ message: 'Error al cargar imagen.', show: true });
    }
  };

  const handleRemoveImage = (productId, index) => {
    if (productId) {
      setProducts(products.map(product =>
        product.id === productId ? { ...product, images: product.images.filter((_, i) => i !== index) } : product
      ));
    } else {
      setNewProduct({ ...newProduct, images: newProduct.images.filter((_, i) => i !== index) });
    }
  };

  const handleSaveChanges = async () => {
    setLoading(true);
    try {
      const updatePromises = products.map(product => {
        const updateData = {
          model: product.model,
          price: parseFloat(product.price),
          category: product.category,
          description: product.description,
          images: product.images
        };

        if (isAdmin) {
          updateData.costPrice = parseFloat(product.costPrice);
          updateData.stock = parseInt(product.stock);
        }

        return updateDoc(doc(db, 'products', product.id), updateData);
      });
      await Promise.all(updatePromises);
      setNotification({ message: 'Productos actualizados con éxito.', show: true });
    } catch (error) {
      console.error("Error updating products:", error);
      setNotification({ message: 'Error al actualizar productos.', show: true });
    } finally {
      setLoading(false);
      setTimeout(() => setNotification({ message: '', show: false }), 3000);
    }
  };

  const handleAddNewProduct = async () => {
    setLoading(true);
    try {
      const newProductData = {
        model: newProduct.model,
        price: parseFloat(newProduct.price),
        category: newProduct.category,
        description: newProduct.description,
        images: newProduct.images
      };

      if (isAdmin) {
        newProductData.costPrice = parseFloat(newProduct.costPrice);
        newProductData.stock = parseInt(newProduct.stock);
      }

      await addDoc(collection(db, 'products'), newProductData);
      setNotification({ message: 'Producto añadido con éxito.', show: true });
      setNewProduct({
        model: '',
        stock: '',
        costPrice: '',
        price: '',
        category: '',
        description: '',
        images: []
      });
      fetchProducts();
    } catch (error) {
      console.error("Error adding new product:", error);
      setNotification({ message: 'Error al añadir nuevo producto.', show: true });
    } finally {
      setLoading(false);
      setTimeout(() => setNotification({ message: '', show: false }), 3000);
    }
  };

  const handleAddNewCategory = () => {
    if (newCategory && !categories.includes(newCategory)) {
      setCategories([...categories, newCategory]);
      setNewProduct({ ...newProduct, category: newCategory });
      setNewCategory('');
    }
  };

  const handleTextareaChange = (e, id) => {
    const textarea = e.target;
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';

    if (id) {
      setProducts(products.map(product =>
        product.id === id ? { ...product, description: e.target.value } : product
      ));
    } else {
      setNewProduct({ ...newProduct, description: e.target.value });
    }
  };

 // Filtrar productos por categoría
const filteredProducts = selectedCategory
? products.filter(product => product.category === selectedCategory)
: products;

// Los productos ya estarán ordenados alfabéticamente por 'model'

  if (loading) return <Spinner />;

  return (
    <div className="products">
      <h2>Editor Masivo de Productos</h2>
      <Notification message={notification.message} show={notification.show} />
      <div className="filter-container">
        <label htmlFor="category-selector">Categoría: </label>
        <select
          id="category-selector"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option value="">Todas las categorías</option>
          {categories.map(cat => (
            <option key={cat} value={cat}>{cat}</option>
          ))}
        </select>
      </div>
      <div className="table-container">
        <table className="mass-editor-table">
          <thead>
            <tr>
              <th>Modelo</th>
              {isAdmin && <th>Stock</th>}
              {isAdmin && <th>Precio de Costo</th>}
              <th>Precio de Venta</th>
              <th>Categoría</th>
              <th>Descripción</th>
              <th>Imagen</th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.map(product => (
              <tr key={product.id}>
                <td data-label="Modelo">
                  <input
                    type="text"
                    value={product.model}
                    onChange={(e) => handleInputChange(product.id, 'model', e.target.value)}
                  />
                </td>
                {isAdmin && (
                  <td data-label="Stock">
                    <input
                      type="number"
                      value={product.stock}
                      onChange={(e) => handleInputChange(product.id, 'stock', e.target.value)}
                    />
                  </td>
                )}
                {isAdmin && (
                  <td data-label="Precio de Costo">
                    <input
                      type="number"
                      value={product.costPrice}
                      onChange={(e) => handleInputChange(product.id, 'costPrice', e.target.value)}
                    />
                  </td>
                )}
                <td data-label="Precio de Venta">
                  <input
                    type="number"
                    value={product.price}
                    onChange={(e) => handleInputChange(product.id, 'price', e.target.value)}
                  />
                </td>
                <td data-label="Categoría">
                  <select
                    value={product.category}
                    onChange={(e) => handleInputChange(product.id, 'category', e.target.value)}
                  >
                    <option value="">Seleccione una categoría</option>
                    {categories.map(cat => (
                      <option key={cat} value={cat}>{cat}</option>
                    ))}
                  </select>
                </td>
                <td data-label="Descripción">
                  <textarea
                    value={product.description}
                    onChange={(e) => handleTextareaChange(e, product.id)}
                    maxLength={500}
                    ref={textareaRef}
                  />
                </td>
                <td data-label="Imagen">
                  <input
                    type="file"
                    onChange={(e) => handleImageUpload(product.id, e.target.files)}
                    multiple
                  />
                  {product.images && product.images.map((image, index) => (
                    <div key={index} className="image-preview-wrapper">
                      <img src={image} alt={product.model} className="image-preview" />
                      <button
                        type="button"
                        onClick={() => handleRemoveImage(product.id, index)}
                        className="remove-image-button"
                      >
                        ×
                      </button>
                    </div>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button onClick={handleSaveChanges} className="mass-editor-save-button">Guardar Cambios</button>

      <h2>Añadir Nuevo Producto</h2>
      <div className="new-product-form">
        <input
          type="text"
          placeholder="Modelo"
          value={newProduct.model}
          onChange={(e) => handleNewProductChange('model', e.target.value)}
        />
        {isAdmin && (
          <input
            type="number"
            placeholder="Stock"
            value={newProduct.stock}
            onChange={(e) => handleNewProductChange('stock', e.target.value)}
          />
        )}
        {isAdmin && (
          <input
            type="number"
            placeholder="Precio de Costo"
            value={newProduct.costPrice}
            onChange={(e) => handleNewProductChange('costPrice', e.target.value)}
          />
        )}
        <input
          type="number"
          placeholder="Precio de Venta"
          value={newProduct.price}
          onChange={(e) => handleNewProductChange('price', e.target.value)}
        />
        <select
          value={newProduct.category}
          onChange={(e) => handleNewProductChange('category', e.target.value)}
        >
          <option value="">Seleccione una categoría</option>
          {categories.map(cat => (
            <option key={cat} value={cat}>{cat}</option>
          ))}
        </select>
        <input
          type="text"
          placeholder="Nueva Categoría"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
        />
        <button onClick={handleAddNewCategory} className="add-new-category-button">Añadir Nueva Categoría</button>
        <input
          type="file"
          onChange={(e) => handleImageUpload(null, e.target.files)}
          multiple
        />
        {newProduct.images && newProduct.images.map((image, index) => (
          <div key={index} className="image-preview-wrapper">
            <img src={image} alt={newProduct.model} className="image-preview" />
            <button
              type="button"
              onClick={() => handleRemoveImage(null, index)}
              className="remove-image-button"
            >
              ×
            </button>
          </div>
        ))}
        <textarea
          placeholder="Descripción"
          value={newProduct.description}
          onChange={(e) => handleTextareaChange(e)}
          maxLength={800}
          ref={textareaRef}
        />
        <button onClick={handleAddNewProduct} className="add-new-product-button">Añadir Producto</button>
      </div>
    </div>
  );
};

export default MassEditor;
