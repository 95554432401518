import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, query, where, getDocs, addDoc } from 'firebase/firestore';
import emailjs from 'emailjs-com';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Spinner from './Spinner';
import './Subscription.css';

const Subscription = () => {
  const [email, setEmail] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    const handleReCaptchaVerify = async () => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }

      setIsLoading(true);
      try {
        const token = await executeRecaptcha('subscribe');
        setRecaptchaToken(token);
      } catch (error) {
        console.error('Error executing reCAPTCHA', error);
      } finally {
        setIsLoading(false);
      }
    };

    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  const handleSubscribe = async (e) => {
    e.preventDefault();

    if (!recaptchaToken) {
      alert('Error de reCAPTCHA, por favor intente nuevamente.');
      return;
    }

    setIsLoading(true);

    try {
      // Verificar si el correo electrónico ya está registrado
      const q = query(collection(db, 'Subscriptions'), where('email', '==', email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        alert('Este correo electrónico ya está suscrito.');
        setIsLoading(false);
        return;
      }

      const subscriptionData = {
        email,
        timestamp: new Date(),
        recaptchaToken,
      };

      const response = await fetch('https://us-central1-priball.cloudfunctions.net/verifyReCaptcha', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: recaptchaToken }),
      });

      const result = await response.json();

      if (result.success) {
        await addDoc(collection(db, 'Subscriptions'), subscriptionData);
        console.log('Subscription successful:', { email });

        // Enviar correo electrónico
        const templateParams = {
          to_name: email,
          to_email: email,
          message: '¡Gracias por suscribirte! Te enviamos un regalo de bienvenida.'
        };

        emailjs.send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID_SUBSCRIPTION,
          templateParams,
          process.env.REACT_APP_EMAILJS_USER_ID
        )
          .then((response) => {
            console.log('Email enviado exitosamente!', response.status, response.text);
          }, (error) => {
            console.error('Error al enviar el email:', error);
          });

        setEmail('');
        alert('¡Gracias por suscribirte! Revisa tu correo electrónico.');
      } else {
        alert('Error de reCAPTCHA, por favor intente nuevamente.');
      }
    } catch (error) {
      console.error('Error al suscribirse:', error);
      alert('Hubo un error al suscribirse. Por favor, intente nuevamente.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="subscription-form">
      <h2>Suscríbete</h2>
      <form onSubmit={handleSubscribe}>
        <div className="form-group">
          <label className='label' htmlFor="email">Recibirás promociones especiales</label>
          <input
            type="email"
            placeholder="Ingresa tu email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="subscription-input"
          />
        </div>
        <button type="submit" className="subscription-button">Suscribirse</button>
      </form>

    </div>
  );
};


export default Subscription;
