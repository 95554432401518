import React, { useRef } from 'react';
import './ImprimirTicket.css';
import logo from './../Logos/Priballl.png';

const formatNumber = (number) => {
  if (typeof number !== 'number' || isNaN(number)) {
    return '0,00';
  }
  return number
    .toFixed(2) // Mantiene dos decimales
    .replace('.', ',') // Reemplaza el punto decimal por una coma
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agrega puntos como separadores de miles
};

const ImprimirTicket = ({ saleInfo }) => {
  const printRef = useRef();

  const calculateTotalWithDiscount = () => {
    const total = saleInfo.products.reduce((total, product) => {
      return total + product.price * product.quantity;
    }, 0);
    const discountAmount = (total * saleInfo.discount) / 100;
    return total - discountAmount;
  };

  return (
    <div>
      <div ref={printRef} className="imprimir-ticket-content">
        <div className="header">
          <img src={logo} alt="Priball Logo" className="logo" />
          <div className="company-info">
            <p>Fábrica de balones deportivos</p>
            <p>Dirección: Ruta 9 y Junin, Bell Ville, Cba.</p>
            <p>Teléfono: (3537)302888</p>
            <p>www.priball.com</p>
          </div>
        </div>
        <div className="order-info">
          <div className="info-item">Número de Orden:</div>
          <div className="info-value">{saleInfo.orderNumber}</div>
          <div className="info-item">Fecha:</div>
          <div className="info-value">{saleInfo.date?.toLocaleString()}</div>
        </div>
        <div className="products-info">
          <h3>Productos:</h3>
          <ul>
            {saleInfo.products.map((product, index) => (
              <li key={index} className="product-item">
                <div className="product-info">
                <p style={{ flex: 1, textAlign: 'center' }}>{product.quantity} U.</p>
                  <p style={{ flex: 3 }}>{product.model}</p>
                  <p style={{ flex: 1, textAlign: 'center' }}>${formatNumber(product.price)}</p>
                  <p style={{ flex: 1, textAlign: 'center' }}>${formatNumber(product.price * product.quantity)}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="summary-info">
          <p>Descuento: {saleInfo.discount}%</p>
          <p>Total con Descuento: ${formatNumber(calculateTotalWithDiscount())}</p>
        </div>
      </div>
    </div>
  );
};

export default ImprimirTicket;
