import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../firebase';
import { collection, getDocs, doc, runTransaction, writeBatch } from 'firebase/firestore';
import Spinner from './Spinner';
import ImprimirTicket from './ImprimirTicket'; // Importa el nuevo componente
import './LocalSales.css';


const formatNumber = (number) => {
  if (typeof number !== 'number' || isNaN(number)) {
    return '0,00';
  }
  return number
    .toFixed(2) // Mantiene dos decimales
    .replace('.', ',') // Reemplaza el punto decimal por una coma
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agrega puntos como separadores de miles
};

const LocalSales = () => {
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [customerData, setCustomerData] = useState({
    name: '',
    cuit: '',
    invoiceType: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    postalCode: '',
    province: '',
    country: '',
    additionalInfo: '',
  });
  const [discount, setDiscount] = useState(0); 
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [saleInfo, setSaleInfo] = useState(null);

  const fetchProducts = useCallback(async () => {
    try {
      const productsCollection = await getDocs(collection(db, 'products'));
      const productsData = productsCollection.docs.map(doc => ({ ...doc.data(), id: doc.id, stock: Number(doc.data().stock) || 0 }));
      setProducts(productsData);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const addProductToSale = (product, quantity) => {
    const parsedQuantity = parseInt(quantity, 10);
    if (parsedQuantity <= 0 || isNaN(parsedQuantity)) {
      return;
    }
    setSelectedProducts(prevSelectedProducts => {
      const existingProductIndex = prevSelectedProducts.findIndex(item => item.id === product.id);
      if (existingProductIndex !== -1) {
        const updatedProducts = [...prevSelectedProducts];
        updatedProducts[existingProductIndex] = {
          ...updatedProducts[existingProductIndex],
          quantity: updatedProducts[existingProductIndex].quantity + parsedQuantity
        };
        return updatedProducts;
      } else {
        return [...prevSelectedProducts, { ...product, quantity: parsedQuantity }];
      }
    });
  };

  const removeProductFromSale = (productId) => {
    setSelectedProducts(prevSelectedProducts => prevSelectedProducts.filter(item => item.id !== productId));
  };

  const handlePriceChange = (productId, newPrice) => {
    const parsedPrice = parseFloat(newPrice);
    if (isNaN(parsedPrice) || parsedPrice < 0) return;

    setSelectedProducts(prevSelected =>
      prevSelected.map(item => item.id === productId ? { ...item, price: parsedPrice } : item)
    );
  };

  const getOrderNumber = async () => {
    const counterRef = doc(db, 'counters', 'fisicalCounter');
    try {
      const newOrderNumber = await runTransaction(db, async (transaction) => {
        const counterDoc = await transaction.get(counterRef);
        if (!counterDoc.exists()) {
          throw new Error('Fisical counter document does not exist!');
        }
        const newOrderNumber = counterDoc.data().currentOrderNumber + 1;
        transaction.update(counterRef, { currentOrderNumber: newOrderNumber });
        return newOrderNumber;
      });
      return newOrderNumber;
    } catch (error) {
      console.error("Transaction failed: ", error);
      alert('Error generando número de orden, por favor intente nuevamente.');
      return null;
    }
  };

  const handleSaleSubmit = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    const orderNumber = await getOrderNumber();
    if (!orderNumber) {
      setIsSubmitting(false);
      return;
    }

    try {
      const batch = writeBatch(db);

      const saleData = {
        orderNumber,
        date: new Date(),
        products: selectedProducts,
        customerData,
        totalPrice: parseFloat(calculateTotal(selectedProducts)),
        discount,
        isConfirmed: true,
      };

      const saleRef = doc(collection(db, 'ventas_fisicas'));
      batch.set(saleRef, saleData);

      selectedProducts.forEach((product) => {
        const productRef = doc(db, 'products', product.id);
        const newStock = product.stock - product.quantity;
        batch.update(productRef, { stock: newStock });
      });

      await batch.commit();
      setSaleInfo(saleData);
      setShowModal(true);
    } catch (error) {
      console.error("Error registering sale:", error);
      alert('Error al registrar la venta. Por favor, intente nuevamente.');
    } finally {
      setIsSubmitting(false);
      fetchProducts();
    }
  };

  const calculateTotal = useCallback((products) => {
    const total = products.reduce((total, item) => {
      const itemTotal = parseFloat(item.price) * parseInt(item.quantity, 10);
      return total + (isNaN(itemTotal) ? 0 : itemTotal);
    }, 0);
    const discountAmount = (total * discount) / 100;
    return total - discountAmount;
  }, [discount]);

  const resetComponent = () => {
    setSelectedProducts([]);
    setCustomerData({
      name: '',
      cuit: '',
      invoiceType: '',
      phone: '',
      email: '',
      address: '',
      city: '',
      postalCode: '',
      province: '',
      country: '',
      additionalInfo: '',
    });
    setDiscount(0);
    setShowAdditionalFields(false);
    setShowModal(false);
    setSaleInfo(null);
    setSearchTerm('');
  };

  const filteredProducts = searchTerm
    ? products.filter(product =>
      product.model.toLowerCase().includes(searchTerm.toLowerCase())
    ).slice(0, 10)
    : [];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleDiscountChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value) && value >= 0) {
      setDiscount(value);
    }
  };

  if (loading) return <Spinner />;



  
  return (
    <div className="local-sales">
      <h2 className="local-sales-title">Registrar Venta Física</h2>
      <input
        type="text"
        placeholder="Buscar producto"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="local-sales-search-input"
      />
      <div className="local-sales-products-list">
        {filteredProducts.map(product => (
          <div key={product.id} className="local-sales-product-item">
            <img src={product.images ? product.images[0] : ''} alt={product.model} className="local-sales-product-image" />
            <div className="local-sales-product-info">
              <span>{product.model} - ${formatNumber(product.price)}</span>
              <span className="local-sales-stock-info">En stock: {product.stock}</span>
              <input
                type="number"
                placeholder="Cantidad"
                min="1"
                max={product.stock}
                onChange={(e) => addProductToSale(product, e.target.value)}
                className="local-sales-quantity-input"
              />
            </div>
          </div>
        ))}
      </div>
      <h3 className="local-sales-selected-title">Productos Seleccionados</h3>
      <ul className="local-sales-selected-list">
        {selectedProducts.map(product => (
          <li key={product.id} className="local-sales-selected-item">
            <span>{product.model} - {product.quantity} unidades</span>
            <div className="local-sales-selected-item-actions">
              <input
                type="number"
                value={product.price}
                onChange={(e) => handlePriceChange(product.id, e.target.value)}
                className="local-sales-price-input"
              />
              <span className="local-sales-product-total">${formatNumber(product.price * product.quantity)}</span>
              <button onClick={() => removeProductFromSale(product.id)} className="local-sales-remove-btn">Eliminar</button>
            </div>
          </li>
        ))}
      </ul>
      <div className="local-sales-discount-container">
        <label>Descuento (%):</label>
        <input
          type="number"
          value={discount}
          onChange={handleDiscountChange}
          className="local-sales-discount-input"
        />
      </div>
      <h4 className="local-sales-total">Total: ${formatNumber(calculateTotal(selectedProducts))}</h4>
      <div className="local-sales-customer-info">
        <h3>Datos del Cliente</h3>
        <input
          type="text"
          name="name"
          placeholder="Nombre del Cliente"
          value={customerData.name}
          onChange={handleInputChange}
          className="local-sales-customer-input"
        />
        <input
          type="text"
          name="cuit"
          placeholder="CUIT"
          value={customerData.cuit}
          onChange={handleInputChange}
          className="local-sales-customer-input"
        />
        <input
          type="text"
          name="invoiceType"
          placeholder="Tipo de Factura"
          value={customerData.invoiceType}
          onChange={handleInputChange}
          className="local-sales-customer-input"
        />
        <button onClick={() => setShowAdditionalFields(!showAdditionalFields)} className="local-sales-additional-fields-btn">
          {showAdditionalFields ? 'Ocultar datos adicionales' : 'Agregar datos de cliente'}
        </button>
        {showAdditionalFields && (
          <>
            <input
              type="text"
              name="phone"
              placeholder="Teléfono"
              value={customerData.phone}
              onChange={handleInputChange}
              className="local-sales-customer-input"
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={customerData.email}
              onChange={handleInputChange}
              className="local-sales-customer-input"
            />
            <input
              type="text"
              name="address"
              placeholder="Dirección"
              value={customerData.address}
              onChange={handleInputChange}
              className="local-sales-customer-input"
            />
            <input
              type="text"
              name="city"
              placeholder="Ciudad"
              value={customerData.city}
              onChange={handleInputChange}
              className="local-sales-customer-input"
            />
            <input
              type="text"
              name="postalCode"
              placeholder="Código Postal"
              value={customerData.postalCode}
              onChange={handleInputChange}
              className="local-sales-customer-input"
            />
            <input
              type="text"
              name="province"
              placeholder="Provincia"
              value={customerData.province}
              onChange={handleInputChange}
              className="local-sales-customer-input"
            />
            <input
              type="text"
              name="country"
              placeholder="País"
              value={customerData.country}
              onChange={handleInputChange}
              className="local-sales-customer-input"
            />
            <textarea
              name="additionalInfo"
              placeholder="Información Adicional"
              value={customerData.additionalInfo}
              onChange={handleInputChange}
              className="local-sales-customer-input"
            />
          </>
        )}
      </div>
      <div className="local-sales-submit-container">
        <button
          onClick={handleSaleSubmit}
          className={`local-sales-submit-btn ${isSubmitting ? 'local-sales-submit-btn-disabled' : ''}`}
          disabled={isSubmitting}
        >
          Registrar Venta
        </button>
        {isSubmitting && <Spinner />}
      </div>
      {showModal && saleInfo && (
        <div className="local-sales-modal">
          <div className="local-sales-modal-content">
            <h2>Venta Registrada</h2>
            <p>Número de Orden: {saleInfo.orderNumber}</p>
            <p>Total: ${formatNumber(saleInfo.totalPrice)}</p>
            <h3>Productos:</h3>
            <ul>
              {saleInfo.products.map((product, index) => (
                <li key={index}>
                  <img
                    src={product.images ? product.images[0] : ''}
                    alt={product.model}
                    className="local-sales-product-image"
                  />
                  {product.model} - {product.quantity} unidades - ${formatNumber(product.price)} c/u - Total: ${formatNumber(product.price * product.quantity)}
                </li>
              ))}
            </ul>
            <div className="local-sales-modal-actions">
              <ImprimirTicket saleInfo={saleInfo} /> 
              <button onClick={resetComponent} className="local-sales-modal-close">Aceptar</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LocalSales;
