import React, { useState } from 'react';
import { db } from '../firebase';
import { updateDoc, doc, getDoc } from 'firebase/firestore';
import emailjs from 'emailjs-com';

// Importa las variables de entorno 
const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID_TRACKING;
const userId = process.env.REACT_APP_EMAILJS_USER_ID;

const TrackingInfo = ({ order, collectionName }) => {
  const [trackingInfo, setTrackingInfo] = useState({ trackingNumber: '', trackingLink: '' });
  const [loading, setLoading] = useState(false);
  const [trackingSent, setTrackingSent] = useState(order.trackingSent);
  const [showInputs, setShowInputs] = useState(!order.trackingSent);

  const handleTrackingInputChange = (e) => {
    const { name, value } = e.target;
    setTrackingInfo((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSaveTrackingInfo = async () => {
    if (!trackingInfo.trackingNumber || !trackingInfo.trackingLink) {
      alert('Por favor, completa ambos campos.');
      return;
    }

    setLoading(true);
    try {
      const orderRef = doc(db, collectionName, order.id);
      const orderDoc = await getDoc(orderRef);

      if (orderDoc.exists()) {
        await updateDoc(orderRef, {
          trackingNumber: trackingInfo.trackingNumber,
          trackingLink: trackingInfo.trackingLink,
          trackingSent: false
        });

        const emailParams = {
          to_name: order.nombre,
          tracking_number: trackingInfo.trackingNumber,
          tracking_link: trackingInfo.trackingLink,
          to_email: order.email,
          order_number: order.orderNumber
        };

        emailjs.send(serviceId, templateId, emailParams, userId)
          .then(async () => {
            await updateDoc(orderRef, { trackingSent: true });
            alert('Número de seguimiento guardado y email enviado.');
            setTrackingInfo({ trackingNumber: '', trackingLink: '' });
            setTrackingSent(true);
            setShowInputs(false);
          }, async (error) => {
            console.error('Error sending email: ', error);
            await updateDoc(orderRef, { trackingSent: false });
            alert('Error enviando email.');
          });
      } else {
        alert('No se encontró el documento para actualizar.');
      }
    } catch (error) {
      console.error("Error saving tracking info: ", error);
      alert('Error guardando número de seguimiento.');
    }
    setLoading(false);
  };

  return (
    <div className="tracking-info">
      {showInputs && (
        <>
          <input
            type="text"
            name="trackingNumber"
            placeholder="Número de Seguimiento"
            value={trackingInfo.trackingNumber}
            onChange={handleTrackingInputChange}
            disabled={loading}
          />
          <input
            type="text"
            name="trackingLink"
            placeholder="Link de Seguimiento"
            value={trackingInfo.trackingLink}
            onChange={handleTrackingInputChange}
            disabled={loading}
          />
        </>
      )}
      <button
        onClick={() => {
          if (trackingSent && !showInputs) {
            setShowInputs(true);
          } else {
            handleSaveTrackingInfo();
          }
        }}
        className={`save-btn ${trackingSent ? 'btn-green' : 'btn-blue'}`}
        disabled={loading}
      >
        {loading ? 'Guardando...' : trackingSent && !showInputs ? 'Número de seguimiento enviado' : 'Cargar y enviar número de seguimiento'}
      </button>
    </div>
  );
};

export default TrackingInfo;
