import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ComposedChart, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Bar, Tooltip, Legend, Line, ReferenceLine } from 'recharts';
import { db } from '../firebase';
import { collection, getDocs, query, orderBy, where } from 'firebase/firestore';
import './WallStreetChart.css';

const WallStreetChart = () => {
  const [ventasMercadoPago, setVentasMercadoPago] = useState([]);
  const [ventasPedidos, setVentasPedidos] = useState([]);
  const [ventasFisicas, setVentasFisicas] = useState([]);
  const [startDateTime, setStartDateTime] = useState(getDefaultStartDateTime());
  const [endDateTime, setEndDateTime] = useState(getDefaultEndDateTime());
  const [loading, setLoading] = useState(false);
  const [activeLines, setActiveLines] = useState({
    salesAmount: false,
    costPrice: false,
    profit: false,
  });

  const fetchSalesData = useCallback(async () => {
    setLoading(true);
    try {
      const fetchMercadoPagoSales = async () => {
        const mercadoPagoCollection = collection(db, 'compras_mercadopago');
        const mercadoPagoQuery = query(
          mercadoPagoCollection,
          orderBy('date', 'asc'),
          where('isConfirmed', '==', true),
          where('date', '>=', new Date(startDateTime)),
          where('date', '<=', new Date(endDateTime))
        );

        const mercadoPagoSnapshot = await getDocs(mercadoPagoQuery);
        return mercadoPagoSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            date: formatDate(data.date?.toDate()) || '',
            salesAmount: data.cartItems ? data.cartItems.reduce((sum, item) => sum + (item.price * item.quantity || 0), 0) : 0,
            costPrice: data.cartItems ? data.cartItems.reduce((sum, item) => sum + (item.costPrice * item.quantity || 0), 0) : 0,
            profit: data.cartItems ? data.cartItems.reduce((sum, item) => sum + ((item.price - item.costPrice) * item.quantity || 0), 0) : 0,
          };
        });
      };

      const fetchPedidosSales = async () => {
        const pedidosCollection = collection(db, 'pedidos');
        const pedidosQuery = query(
          pedidosCollection,
          orderBy('date', 'asc'),
          where('isConfirmed', '==', true),
          where('date', '>=', new Date(startDateTime)),
          where('date', '<=', new Date(endDateTime))
        );

        const pedidosSnapshot = await getDocs(pedidosQuery);
        return pedidosSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            date: formatDate(data.date?.toDate()) || '',
            salesAmount: data.cartItems ? data.cartItems.reduce((sum, item) => sum + (item.price * item.quantity || 0), 0) : 0,
            costPrice: data.cartItems ? data.cartItems.reduce((sum, item) => sum + (item.costPrice * item.quantity || 0), 0) : 0,
            profit: data.cartItems ? data.cartItems.reduce((sum, item) => sum + ((item.price - item.costPrice) * item.quantity || 0), 0) : 0,
          };
        });
      };

      const fetchPhysicalSales = async () => {
        const physicalSalesCollection = collection(db, 'ventas_fisicas');
        const physicalSalesQuery = query(
          physicalSalesCollection,
          orderBy('date', 'asc'),
          where('isConfirmed', '==', true),
          where('date', '>=', new Date(startDateTime)),
          where('date', '<=', new Date(endDateTime))
        );

        const physicalSalesSnapshot = await getDocs(physicalSalesQuery);
        return physicalSalesSnapshot.docs.map(doc => {
          const data = doc.data();
          const salesAmount = data.totalPrice || 0;
          const costPrice = data.products ? data.products.reduce((sum, item) => sum + (item.costPrice * item.quantity || 0), 0) : 0;
          return {
            date: formatDate(data.date?.toDate()) || '',
            salesAmount: salesAmount,
            costPrice: costPrice,
            profit: salesAmount - costPrice,
          };
        });
      };

      const [mercadoPagoData, pedidosData, fisicasData] = await Promise.all([
        fetchMercadoPagoSales(),
        fetchPedidosSales(),
        fetchPhysicalSales()
      ]);

      setVentasMercadoPago(aggregateSalesData(mercadoPagoData));
      setVentasPedidos(aggregateSalesData(pedidosData));
      setVentasFisicas(aggregateSalesData(fisicasData));

      setLoading(false);
    } catch (error) {
      console.error('Error fetching sales data:', error);
      setLoading(false);
    }
  }, [startDateTime, endDateTime]);

  const formatDate = (date) => {
    if (!date) return '';
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatNumber = (price) => {
    return price
      .toFixed(2) // Mantiene dos decimales
      .replace('.', ',') // Reemplaza el punto decimal por una coma
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agrega puntos como separadores de miles
  };
  const aggregateSalesData = (salesData) => {
    const result = {};

    salesData.forEach(sale => {
      const dateKey = sale.date;
      if (!result[dateKey]) {
        result[dateKey] = { salesAmount: 0, costPrice: 0, profit: 0 };
      }
      result[dateKey].salesAmount += sale.salesAmount || 0;
      result[dateKey].costPrice += sale.costPrice || 0;
      result[dateKey].profit += sale.profit || 0;
    });

    return Object.keys(result).map(date => ({
      date,
      ...result[date]
    }));
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`Fecha: ${label}`}</p>
          {payload.map((entry, index) => (
            <p key={`item-${index}`} style={{ color: entry.color }}>
              {`${entry.name}: ${formatNumber(entry.value)}`}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  const handleLegendClick = (dataKey) => {
    setActiveLines((prevState) => ({
      ...prevState,
      [dataKey]: !prevState[dataKey],
    }));
  };

  useEffect(() => {
    fetchSalesData();
  }, [fetchSalesData]);

  const calculateTotals = useCallback((data) => {
    return data.reduce((totals, item) => {
      totals.salesAmount += item.salesAmount || 0;
      totals.costPrice += item.costPrice || 0;
      totals.profit += item.profit || 0;
      return totals;
    }, { salesAmount: 0, costPrice: 0, profit: 0 });
  }, []);

  const calculateDailyAverage = (total, data) => {
    const numberOfDays = data.length;
    return numberOfDays > 0 ? total / numberOfDays : 0;
  };

  const totalsMercadoPago = useMemo(() => calculateTotals(ventasMercadoPago), [ventasMercadoPago, calculateTotals]);
  const totalsPedidos = useMemo(() => calculateTotals(ventasPedidos), [ventasPedidos, calculateTotals]);
  const totalsFisicas = useMemo(() => calculateTotals(ventasFisicas), [ventasFisicas, calculateTotals]);

  const dailyAverageMercadoPago = useMemo(() => ({
    salesAmount: calculateDailyAverage(totalsMercadoPago.salesAmount, ventasMercadoPago),
    costPrice: calculateDailyAverage(totalsMercadoPago.costPrice, ventasMercadoPago),
    profit: calculateDailyAverage(totalsMercadoPago.profit, ventasMercadoPago),
  }), [totalsMercadoPago, ventasMercadoPago]);

  const dailyAveragePedidos = useMemo(() => ({
    salesAmount: calculateDailyAverage(totalsPedidos.salesAmount, ventasPedidos),
    costPrice: calculateDailyAverage(totalsPedidos.costPrice, ventasPedidos),
    profit: calculateDailyAverage(totalsPedidos.profit, ventasPedidos),
  }), [totalsPedidos, ventasPedidos]);

  const dailyAverageFisicas = useMemo(() => ({
    salesAmount: calculateDailyAverage(totalsFisicas.salesAmount, ventasFisicas),
    costPrice: calculateDailyAverage(totalsFisicas.costPrice, ventasFisicas),
    profit: calculateDailyAverage(totalsFisicas.profit, ventasFisicas),
  }), [totalsFisicas, ventasFisicas]);

  const renderChart = (data, title, totals, dailyAverage) => (
    <>
      <h4>{title}</h4>
      <div className="totals-summary">
        <p style={{ color: '#3b82f6' }}>
          Total Ventas: <span style={{ color: '#aaaaaa' }}>$ {formatNumber(totals.salesAmount)}</span><br />
          Promedio Diario: <span style={{ color: '#aaaaaa' }}>$ {formatNumber(dailyAverage.salesAmount)}</span>
        </p>
        <p style={{ color: '#ef4444' }}>
          Total Costo: <span style={{ color: '#aaaaaa' }}>$ {formatNumber(totals.costPrice)}</span><br />
          Promedio Diario: <span style={{ color: '#aaaaaa' }}>$ {formatNumber(dailyAverage.costPrice)}</span>
        </p>
        <p style={{ color: '#10b981' }}>
          Total Ganancia: <span style={{ color: '#aaaaaa' }}>$ {formatNumber(totals.profit)}</span><br />
          Promedio Diario: <span style={{ color: '#aaaaaa' }}>$ {formatNumber(dailyAverage.profit)}</span>
        </p>
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend onClick={(e) => handleLegendClick(e.dataKey)} />
          <Bar dataKey="salesAmount" fill="#3b82f6" name="Total Ventas" barSize={20} />
          <Bar dataKey="costPrice" fill="#ef4444" name="Precio de Costo" barSize={20} stackId="stack" />
          <Bar dataKey="profit" fill="#10b981" name="Ganancia" barSize={20} stackId="stack" />
          {activeLines.salesAmount && (
            <Line
              type="monotone"
              dataKey="salesAmount"
              stroke="#3b82f6"
              dot={false}
              activeDot={{ r: 8 }}
            />
          )}
          {activeLines.costPrice && (
            <Line
              type="monotone"
              dataKey="costPrice"
              stroke="#ef4444"
              dot={false}
              activeDot={{ r: 8 }}
            />
          )}
          {activeLines.profit && (
            <Line
              type="monotone"
              dataKey="profit"
              stroke="#10b981"
              dot={false}
              activeDot={{ r: 8 }}
            />
          )}
          {activeLines.salesAmount && (
            <ReferenceLine y={totals.salesAmount} label={`Total Ventas: ${formatNumber(totals.salesAmount)}`} stroke="#3b82f6" />
          )}
          {activeLines.costPrice && (
            <ReferenceLine y={totals.costPrice} label={`Total Costo: ${formatNumber(totals.costPrice)}`} stroke="#ef4444" />
          )}
          {activeLines.profit && (
            <ReferenceLine y={totals.profit} label={`Total Ganancia: ${formatNumber(totals.profit)}`} stroke="#10b981" />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );

  return (
    <div className="wallStreetChart-container">
      <h3>Informe de Ventas Diarias</h3>
      <div className="wallStreetChart-filters">
        <input
          type="datetime-local"
          value={startDateTime}
          onChange={(e) => setStartDateTime(e.target.value)}
          placeholder="Fecha y hora de inicio"
          className="wallStreetChart-filterInput"
        />
        <input
          type="datetime-local"
          value={endDateTime}
          onChange={(e) => setEndDateTime(e.target.value)}
          placeholder="Fecha y hora de fin"
          className="wallStreetChart-filterInput"
        />
      </div>
      {loading ? (
        <p>Cargando datos...</p>
      ) : (
        <>
          {renderChart(ventasMercadoPago, "Ventas MercadoPago", totalsMercadoPago, dailyAverageMercadoPago)}
          {renderChart(ventasPedidos, "Ventas Pedidos", totalsPedidos, dailyAveragePedidos)}
          {renderChart(ventasFisicas, "Ventas Físicas", totalsFisicas, dailyAverageFisicas)}
        </>
      )}
    </div>
  );
};

// Función para obtener la fecha y hora de inicio predeterminada (el primer día del mes actual a las 00:00)
const getDefaultStartDateTime = () => {
  const now = new Date();
  return `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-01T00:00`;
};

// Función para obtener la fecha y hora de fin predeterminada (el último día del mes actual a las 23:59)
const getDefaultEndDateTime = () => {
  const now = new Date();
  const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
  return `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${lastDayOfMonth}T23:59`;
};

export default WallStreetChart;
