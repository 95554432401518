import React from 'react';
import './DeliverNote.css';
import logo from './../Logos/Priballl.png';

const DeliveryNote = ({ order }) => {
  return (
    <div className="delivery-note print-only">
      <div className="page">
        <div className="header">
          <img src={logo} alt="Priball Logo" className="logo" />
          <div className="company-info">
            <p>Fabrica de balones deportivos</p>
            <p>Dirección: Ruta 9 y Junin, Bell Ville, Cba.</p>
            <p>Teléfono: (3537)302888 </p>
            <p>Titular: Mariano Privitera</p>
            <p>www.priball.com</p>
          </div>
        </div>
        <div className="order-info">
          <div className="info-item">Número de Pedido:</div>
          <div className="info-value">{order.orderNumber}</div>
          <div className="info-item">Fecha:</div>
          <div className="info-value">{order.date?.toLocaleString()}</div>
          <div className="info-item">Nombre:</div>
          <div className="info-value">{order.nombre}</div>
          <div className="info-item">Teléfono:</div>
          <div className="info-value">{order.telefono}</div>
          <div className="info-item">Dirección:</div>
          <div className="info-value">{`${order.domicilio}`}</div>
          <div className="info-item">Ciudad:</div>
          <div className="info-value">{`${order.localidad}, CP: ${order.codigoPostal}`}</div>
          <div className="info-item">Provincia:</div>
          <div className="info-value">{order.provincia}</div>
          <div className="info-item">País:</div>
          <div className="info-value">{order.pais}</div>
          <div className="info-item">Información Adicional:</div>
          <div className="info-value">{order.informacionAdicional}</div>
        </div>
        <div className="products-info">
          <h1>Productos:</h1>
          <div className="products-grid">
            {order.cartItems?.map((item, index) => (
              <div key={index} className="product-item">
                <div className="product-info">
                  <p>Modelo: {item.model}</p>
                  <p>Cantidad: {item.quantity}</p>
                  <p>Precio: ${item.price}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Página 2: Información de Envío */}
      <div className="page shipping-page">
        <div className="shipping-label">
          <div className="header">
            <img src={logo} alt="Priball Logo" className="logo" />
            <div className="company-info">
              <p>Fabrica de balones deportivos</p>
              <p>Dirección: Ruta 9 y Junin, Bell Ville, Cba.</p>
              <p>Teléfono: (3537)302888 </p>
              <p>www.priball.com</p>
            </div>
          </div>
          <div className="shipping-details-container">
            <div className="shipping-details">
              <h2>Remitente:</h2>
              <div className="shipping-field">
                <strong>Nombre:</strong> Priball Balones deportivos, de Mariano Privitera
              </div>
              <div className="shipping-field">
                <strong>Dirección:</strong> Ruta 9 y Junin, Bell Ville, Cba.
              </div>
              <div className="shipping-field">
                <strong>Teléfono:</strong> (3537)302888
              </div>
              <div className="shipping-field">
                <strong>Localidad:</strong> Bell Ville
              </div>
              <div className="shipping-field">
                <strong>Código Postal:</strong> 2550
              </div>
              <div className="shipping-field">
                <strong>Provincia:</strong> Córdoba
              </div>
              <div className="shipping-field">
                <strong>País:</strong> Argentina
              </div>
            </div>
            <div className="shipping-details">
              <h2>Destinatario:</h2>
              <div className="shipping-field">
                <strong>Nombre:</strong> {order.nombre}
              </div>
              <div className="shipping-field">
                <strong>Dirección:</strong> {order.domicilio}
              </div>
              <div className="shipping-field">
                <strong>Teléfono:</strong> {order.telefono}
              </div>
              <div className="shipping-field">
                <strong>Localidad:</strong> {order.localidad}
              </div>
              <div className="shipping-field">
                <strong>Código Postal:</strong> {order.codigoPostal}
              </div>
              <div className="shipping-field">
                <strong>Provincia:</strong> {order.provincia}
              </div>
              <div className="shipping-field">
                <strong>País:</strong> {order.pais}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryNote;
