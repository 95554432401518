import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, getDocs, doc, updateDoc, deleteDoc, addDoc, setDoc, getDoc } from 'firebase/firestore';
import Notification from './Notification';
import Spinner from './Spinner';
import ProductForm from './ProductForm';
import ProductDetailModal from './ProductDetailModal';
import './Products.css';

const formatNumber = (number) => {
  if (typeof number !== 'number' || isNaN(number)) {
    return '0,00';
  }
  return number
    .toFixed(2) // Mantiene dos decimales
    .replace('.', ',') // Reemplaza el punto decimal por una coma
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agrega puntos como separadores de miles
};

const Products = ({ cart, setCart }) => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [showAvailableOnly, setShowAvailableOnly] = useState(false);
  const [notification, setNotification] = useState({ message: '', show: false });
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editProduct, setEditProduct] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isVacationMode, setIsVacationMode] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const navigate = useNavigate();

  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  const isAdmin = localStorage.getItem('isAdmin') === 'true';

  const fetchProducts = useCallback(async () => {
    try {
      const productsCollection = await getDocs(collection(db, 'products'));
      const productsData = productsCollection.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setProducts(productsData);

      const uniqueCategories = [...new Set(productsData.map(product => product.category))];
      setCategories(uniqueCategories);

      const vacationDoc = await getDoc(doc(db, 'settings', 'vacationMode'));
      if (vacationDoc.exists()) {
        setIsVacationMode(vacationDoc.data().isVacationMode);
        if (vacationDoc.data().isVacationMode) {
          setNotification({ message: 'Estamos de vacaciones, volvemos pronto.', show: true });
        }
      }
    } catch (error) {
      console.error("Error fetching products:", error);
      setNotification({ message: 'Error al cargar productos.', show: true });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);


  const addToCart = (product, quantity) => {
    const existingProduct = cart.find(item => item.id === product.id);
    const existingQuantity = existingProduct ? existingProduct.quantity : 0;

    if (product.stock < quantity + existingQuantity) {
      setNotification({ message: 'No hay suficiente stock disponible.', show: true });
      return;
    }

    const updatedCart = existingProduct
      ? cart.map(item => item.id === product.id ? { ...item, quantity: item.quantity + quantity } : item)
      : [...cart, { ...product, quantity }];

    setCart(updatedCart);
    setNotification({ message: `Añadido ${quantity} ${product.model} al carrito.`, show: true });
    setTimeout(() => setNotification({ message: '', show: false }), 1000);
  };

  const handleAction = async (action, product) => {
    if (!isAdmin && (action === 'edit' || action === 'delete' || action === 'duplicate')) {
      navigate('/login');
      return;
    }
    if (action === 'edit') {
      setEditProduct(product);
      setIsModalOpen(true);
    } else if (action === 'delete') {
      handleDelete(product);
    } else if (action === 'view') {
      setSelectedProduct(product);
      setShowProducts(false);
    } else if (action === 'duplicate') {
      try {
        const newProductData = { ...product, model: `${product.model} (copy)` };
        delete newProductData.id;
        newProductData.images = [...product.images];
        await addDoc(collection(db, 'products'), newProductData);
        fetchProducts();
        setNotification({ message: 'Producto duplicado con éxito.', show: true });
      } catch (error) {
        console.error("Error duplicating product: ", error);
        setNotification({ message: 'Error al duplicar el producto.', show: true });
      }
    }
  };

  const handleDelete = async (product) => {
    const confirmDelete = window.confirm("¿Estás seguro de que deseas eliminar este producto?");
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, 'products', product.id));
        setProducts(products.filter(p => p.id !== product.id));
        setNotification({ message: 'Producto eliminado con éxito.', show: true });
      } catch (error) {
        console.error("Error al eliminar el producto: ", error);
        setNotification({ message: 'Error al eliminar el producto.', show: true });
      } finally {
        setTimeout(() => setNotification({ message: '', show: false }), 3000);
      }
    }
  };

  const handleProductSubmit = async (productData) => {
    setLoading(true);
    try {
      if (editProduct) {
        await updateDoc(doc(db, 'products', editProduct.id), productData);
        setProducts(products.map(p => p.id === editProduct.id ? { ...productData, id: editProduct.id } : p));
        setNotification({ message: 'Producto actualizado con éxito.', show: true });
      } else {
        const docRef = await addDoc(collection(db, 'products'), productData);
        setProducts([...products, { ...productData, id: docRef.id }]);
        setNotification({ message: 'Producto agregado con éxito.', show: true });
      }
      setIsModalOpen(false);
      setEditProduct(null);
      fetchProducts();
    } catch (error) {
      console.error("Error al guardar el producto: ", error);
      setNotification({ message: 'Error al guardar el producto.', show: true });
    } finally {
      setLoading(false);
      setTimeout(() => setNotification({ message: '', show: false }), 3000);
    }
  };

  const handleToggleVacationMode = async () => {
    if (isAdmin) {
      const newVacationMode = !isVacationMode;
      try {
        await setDoc(doc(db, 'settings', 'vacationMode'), { isVacationMode: newVacationMode });
        setIsVacationMode(newVacationMode);
        setNotification({ message: newVacationMode ? 'Estamos de vacaciones, volvemos pronto.' : 'Modo vacaciones desactivado.', show: true });
        setTimeout(() => setNotification({ message: '', show: false }), 3000);
      } catch (error) {
        console.error("Error updating vacation mode: ", error);
        setNotification({ message: 'Error al actualizar el modo vacaciones.', show: true });
      }
    }
  };

  const filteredProducts = products
  .filter(product =>
    (selectedCategory === 'all' || product.category === selectedCategory) &&
    (!showAvailableOnly || product.stock > 0) &&
    (!product.hidden || isAdmin)
  )
  .sort((a, b) => a.model.localeCompare(b.model));  // Ordena los productos alfabéticamente por modelo


  if (loading) return <Spinner />;

  return (
    <div className="products-container">
      <Notification message={notification.message} show={notification.show} />
      <div className="category-buttons-container">
  <div className="dropdown-container">
    <button className="category-button" onClick={() => setShowProducts(!showProducts)}>PRODUCTOS POR CATEGORIA</button>
    {showProducts && (
      <div className="dropdown-content-container">
        {categories
          .sort((a, b) => a.localeCompare(b))  // Ordena las categorías alfabéticamente
          .map(category => (
            <button 
              key={category} 
              onClick={() => { 
                setSelectedCategory(category); 
                setShowAvailableOnly(false); 
                setShowProducts(false); 
              }} 
              className="dropdown-content-button"
            >
              {category}
            </button>
          ))}
      </div>
    )}
  </div>
  {isAdmin && <button onClick={handleToggleVacationMode} className="category-button">{isVacationMode ? 'Desactivar Modo Vacaciones' : 'Activar Modo Vacaciones'}</button>}
</div>
{isModalOpen && (
  <div className="modal-overlay-container">
    <div className="modal-content-container">
      <ProductForm
        product={editProduct}
        onSubmit={handleProductSubmit}
        onCancel={() => {
          setIsModalOpen(false);
          setEditProduct(null);
        }}
        categories={categories}
        setCategories={setCategories}
      />
    </div>
  </div>
)}
{selectedProduct && (
  <ProductDetailModal
    product={selectedProduct}
    onClose={() => setSelectedProduct(null)}
  />
)}
<div className="ball-list-container">
  {filteredProducts
    .sort((a, b) => a.model.localeCompare(b.model))  // Ordena los productos alfabéticamente por modelo
    .map((product) => (
      <ProductItem
        key={product.id}
        product={product}
        addToCart={addToCart}
        isAuthenticated={isAuthenticated}
        isAdmin={isAdmin}
        handleAction={handleAction}
        isVacationMode={isVacationMode}
      />
    ))}
</div>
{isAdmin && <button onClick={() => setIsModalOpen(true)} className="category-button">Agregar Producto</button>}

    </div>
  );
};

const ProductItem = ({ product, addToCart, isAuthenticated, isAdmin, handleAction, isVacationMode }) => {
  const [quantity, setQuantity] = useState(1);

  return (
    <div className={`product-item-container ${product.stock === 0 ? 'out-of-stock' : ''} ${isVacationMode ? 'vacation-mode' : ''} ${product.hidden ? 'hidden' : ''}`}>
      <BallCard
        model={product.model}
        image={product.images ? product.images[0] : ''}
        price={`$${formatNumber(product.price)}`}
        category={product.category}
        onClick={() => handleAction('view', product)}
      />
      <div className="product-actions-container">
        <label htmlFor={`quantity-${product.id}`} className="product-action-label">Cantidad:</label>
        <input
          type="number"
          id={`quantity-${product.id}`}
          value={quantity}
          min={1}
          max={product.stock}
          onChange={(e) => setQuantity(Number(e.target.value))}
          className="product-action-input"
        />
        <button className="product-action-button add" onClick={() => addToCart(product, quantity)} disabled={product.stock === 0 || isVacationMode}>
          {product.stock > 0 ? 'Agregar al Carrito' : 'Sin Stock'}
        </button>
        {isAuthenticated && (
          <>
            <button className="product-action-button" onClick={() => handleAction('edit', product)}>Editar</button>
            {isAdmin && (
              <>
                <button className="product-action-button delete" onClick={() => handleAction('delete', product)}>Eliminar</button>
                <button className="product-action-button duplicate" onClick={() => handleAction('duplicate', product)}>Duplicar Producto</button>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const BallCard = ({ model, image, price, onClick }) => { 
  return (
    <div className="ball-card-container" onClick={onClick}> 
      <img src={image} alt={model} className="ball-card-image" />
      <h3 className="ball-card-title">{model}</h3>
      <p className="ball-card-price">{price}</p>
    </div>
  );
};


export default Products;
