import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Footer.css';
import logoTag from './../images/logosMarcas/logoTag.png';
import logoPritty from './../images/logosMarcas/logoPritty.png';
import logoPolka from './../images/logosMarcas/logoPolka.png';
import logoKay from './../images/logosMarcas/logoKay.png';
import logoJager from './../images/logosMarcas/logoJager.png';
import logoInsta from './../images/logosMarcas/logoInsta.png';
import logoDisney from './../images/logosMarcas/logoDisney.png';
import logoBoca from './../images/logosMarcas/logoBoca.png';
import logoAllBoys from './../images/logosMarcas/logoAllBoys.png';

const Footer = () => {
  const location = useLocation();
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [isLogosVisible, setIsLogosVisible] = useState(false);

  useEffect(() => {
    const footerTimer = setTimeout(() => {
      setIsFooterVisible(true);
    }, 5500); 

    const logosTimer = setTimeout(() => {
      setIsLogosVisible(true);
    }, 6500); 

    return () => {
      clearTimeout(footerTimer);
      clearTimeout(logosTimer);
    }; 
  }, []);

  const isAdminRoute = location.pathname.startsWith('/admin') || location.pathname.startsWith('/subscriptores') || 
                       location.pathname.startsWith('/editor-masivo') || location.pathname.startsWith('/historial-ventas') || 
                       location.pathname.startsWith('/reporte-de-ventas') || location.pathname.startsWith('/local') || 
                       location.pathname.startsWith('/configuracion-descuentos')  || location.pathname.startsWith('/gestion-stock') || 
                       location.pathname.startsWith('/grafico')  ||  location.pathname.startsWith('/costo-envios')  ;
                      

  return (
    <footer className={`footer ${isFooterVisible ? 'visible' : 'hidden'}`}>
      {isAdminRoute ? (
        <h1>PRIVISTORE</h1>
      ) : (
        <>
          <h4>Nos eligieron:</h4>
          <div className={`footer-logos-container ${isLogosVisible ? 'visible' : 'hidden'}`}>
            <div className="footer-logos">
              <img src={logoTag} alt="Logo Tag" style={{ height: '50px', width: 'auto' }} />
              <img src={logoPritty} alt="Logo Pritty" style={{ height: '50px', width: 'auto' }} />
              <img src={logoPolka} alt="Logo Polka" style={{ height: '50px', width: 'auto' }} />
              <img src={logoKay} alt="Logo Kay" style={{ height: '50px', width: 'auto' }} />
              <img src={logoJager} alt="Logo Jager" style={{ height: '50px', width: 'auto' }} />
              <img src={logoInsta} alt="Logo Insta" style={{ height: '50px', width: 'auto' }} />
              <img src={logoDisney} alt="Logo Disney" style={{ height: '50px', width: 'auto' }} />
              <img src={logoBoca} alt="Logo Boca" style={{ height: '50px', width: 'auto' }} />
              <img src={logoAllBoys} alt="Logo All Boys" style={{ height: '50px', width: 'auto' }} />
            </div>
          </div>
          <div className="footer-contact">
            <h4>Priball Balones Deportivos</h4>
            <p>Ruta 9 y Junin, Km 501</p>
            <p>Bell Ville, Córdoba, Argentina.</p>
            <p>Teléfono: +54 9 3537 302888</p>
          </div>
          <div className="footer-developer">
            <p>Desarrollado por Mariano Privitera</p>
            <a href="http://qr.afip.gob.ar/?qr=UACey0imz4v1lrGAiz8Tnw,," target="_F960AFIPInfo">
              <img src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg" border="0" alt="AFIP Info" style={{ width: '60px', height: 'auto' }} />
            </a>
          </div>
        </>
      )}
    </footer>
  );
};

export default Footer;
