import React from 'react';
import './PaginasMP.css';

const Pending = () => {
  return (
    <div className="pending">
      <h1>Pago Pendiente</h1>
      <p>Tu pago está en proceso. Te notificaremos cuando se haya completado.</p>
      <p>Puedes cerrar esta ventana.</p>
    </div>
  );
};

export default Pending;
