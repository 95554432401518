import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';
import './CostoEnvio.css';
import Notification from './Notification';
import Spinner from './Spinner';

const CostoEnvio = () => {
  const [shippingCosts, setShippingCosts] = useState([]);
  const [minQuantity, setMinQuantity] = useState('');
  const [shippingCost, setShippingCost] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({ message: '', show: false });

  useEffect(() => {
    const fetchShippingCosts = async () => {
      setIsLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, 'shippingCosts'));
        const fetchedShippingCosts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setShippingCosts(fetchedShippingCosts);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        showNotification('Error al cargar los costos de envío.');
        console.error("Error fetching shipping costs: ", error);
      }
    };

    fetchShippingCosts();
  }, []);

  const showNotification = (message) => {
    setNotification({ message, show: true });
    setTimeout(() => setNotification({ message: '', show: false }), 3000);
  };

  const handleAddShippingCost = async () => {
    if (minQuantity === '' || shippingCost === '') {
      showNotification('Por favor complete todos los campos.');
      return;
    }

    setIsLoading(true);
    try {
      const newShippingCost = { minQuantity: Number(minQuantity), shippingCost: Number(shippingCost) };
      await addDoc(collection(db, 'shippingCosts'), newShippingCost);
      setShippingCosts([...shippingCosts, newShippingCost]);
      setMinQuantity('');
      setShippingCost('');
      setIsLoading(false);
      showNotification('Costo de envío agregado exitosamente.');
    } catch (error) {
      setIsLoading(false);
      showNotification('Error al agregar el costo de envío.');
      console.error("Error adding shipping cost: ", error);
    }
  };

  const handleDeleteShippingCost = async (id) => {
    setIsLoading(true);
    try {
      await deleteDoc(doc(db, 'shippingCosts', id));
      setShippingCosts(shippingCosts.filter(cost => cost.id !== id));
      setIsLoading(false);
      showNotification('Costo de envío eliminado exitosamente.');
    } catch (error) {
      setIsLoading(false);
      showNotification('Error al eliminar el costo de envío.');
      console.error("Error deleting shipping cost: ", error);
    }
  };

  return (
    <div className="shipping-cost-config">
      <Notification message={notification.message} show={notification.show} />
      {isLoading && <Spinner />}
      <h2 className="shipping-cost-config-title">Configuración de Costos de Envío</h2>

      {/* Formulario para agregar costo de envío */}
      <div className="shipping-cost-form">
        <h3>Agregar Costo de Envío</h3>
        <input
          type="number"
          placeholder="Cantidad mínima"
          value={minQuantity}
          onChange={(e) => setMinQuantity(e.target.value)}
          className="shipping-cost-input"
        />
        <input
          type="number"
          placeholder="Costo de envío"
          value={shippingCost}
          onChange={(e) => setShippingCost(e.target.value)}
          className="shipping-cost-input"
        />
        <button onClick={handleAddShippingCost} className="shipping-cost-button">Agregar Costo de Envío</button>
      </div>

      {/* Lista de costos de envío actuales */}
      <div className="shipping-cost-list">
        <h3 className="shipping-cost-list-title">Costos de Envío Actuales</h3>
        {shippingCosts.map(cost => (
          <div key={cost.id} className="shipping-cost-item">
            <span className="shipping-cost-detail">Cantidad mínima: {cost.minQuantity}</span>
            <span className="shipping-cost-detail">Costo de envío: ${cost.shippingCost}</span>
            <button onClick={() => handleDeleteShippingCost(cost.id)} className="shipping-cost-delete-button">Eliminar</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CostoEnvio;
