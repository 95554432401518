import React, { useEffect, useState, useCallback } from 'react';
import { db } from '../firebase';
import { collection, getDocs, query, orderBy, doc, runTransaction } from 'firebase/firestore';
import './RenamedHistorySales.css';
import Spinner from './Spinner';
import Notification from './Notification';

const ITEMS_PER_PAGE = 10;

const formatNumber = (number) => {
  if (typeof number !== 'number' || isNaN(number)) {
    return '0,00';
  }
  return number
    .toFixed(2) // Mantiene dos decimales
    .replace('.', ',') // Reemplaza el punto decimal por una coma
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agrega puntos como separadores de miles
};
const RenamedHistorySales = () => {
  const getFirstDayOfMonth = useCallback(() => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1);
  }, []);

  const getLastDayOfMonth = useCallback(() => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + 1, 0);
  }, []);

  const [orderSales, setOrderSales] = useState([]);
  const [mercadoPagoSales, setMercadoPagoSales] = useState([]);
  const [physicalSales, setPhysicalSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState({ message: '', show: false });
  const [selectedSale, setSelectedSale] = useState({ id: null, type: null });
  const [searchOrder, setSearchOrder] = useState('');
  const [searchName, setSearchName] = useState('');
  const [itemsToShow, setItemsToShow] = useState(ITEMS_PER_PAGE);
  const [startDate, setStartDate] = useState(getFirstDayOfMonth());
  const [endDate, setEndDate] = useState(getLastDayOfMonth());
  const [activeTab, setActiveTab] = useState('order');

  useEffect(() => {
    setStartDate(getFirstDayOfMonth());
    setEndDate(getLastDayOfMonth());
  }, [getFirstDayOfMonth, getLastDayOfMonth]);

  const fetchSales = useCallback(async (collectionName, setSales, transformData) => {
    setLoading(true);
    try {
      const salesCollection = collection(db, collectionName);
      const salesQuery = query(salesCollection, orderBy('date', 'desc'));
      const salesSnapshot = await getDocs(salesQuery);
      const salesList = salesSnapshot.docs.map(doc => transformData(doc));
      setSales(salesList);
    } catch (error) {
      console.error(`Error fetching ${collectionName} sales:`, error);
      setNotification({ message: `Error al cargar ventas de ${collectionName}.`, show: true });
    }
    setLoading(false);
  }, []);

  const transformOrderData = (doc) => {
    const data = doc.data();
    return {
      id: doc.id,
      orderNumber: data.orderNumber,
      customerName: data.nombre,
      address: `${data.domicilio}, ${data.localidad}, ${data.codigoPostal}, ${data.provincia}, ${data.pais}`,
      email: data.email,
      phone: data.telefono,
      ...data,
      date: data.date,
      cartItems: (data.cartItems || []).map(item => ({
        ...item,
        image: item.images && item.images[0] ? item.images[0] : ''
      })),
      isConfirmed: data.isConfirmed || false,
      subtotal: data.subtotal || 0,
      discountAmount: data.discountAmount || 0,
      discountDetail: data.discountDetail || '',
      totalPrice: data.totalPrice || 0
    };
  };

  const transformMercadoPagoData = (doc) => {
    const data = doc.data();
    return {
      id: doc.id,
      orderNumber: data.orderNumber,
      customerName: data.nombre,
      address: `${data.domicilio}, ${data.localidad}, ${data.codigoPostal}, ${data.provincia}, ${data.pais}`,
      email: data.email,
      phone: data.telefono,
      ...data,
      date: data.date,
      cartItems: (data.cartItems || []).map(item => ({
        ...item,
        image: item.images && item.images[0] ? item.images[0] : ''
      })),
      isConfirmed: data.isConfirmed || false,
      subtotal: data.subtotal || 0,
      discountAmount: data.discountAmount || 0,
      discountDetail: data.discountDetail || '',
      totalPrice: data.totalPrice || 0
    };
  };

  const transformPhysicalData = (doc) => {
    const data = doc.data();
    return {
      id: doc.id,
      orderNumber: data.orderNumber,
      customerName: data.customerData.name,
      cuit: data.customerData.cuit,
      invoiceType: data.customerData.invoiceType,
      address: `${data.customerData.address}, ${data.customerData.city}, ${data.customerData.postalCode}, ${data.customerData.province}, ${data.customerData.country}`,
      email: data.customerData.email,
      phone: data.customerData.phone,
      additionalInfo: data.customerData.additionalInfo,
      ...data,
      date: data.date,
      cartItems: (data.products || []).map(item => ({
        ...item,
        image: item.images && item.images[0] ? item.images[0] : ''
      })),
      isConfirmed: data.isConfirmed || false,
      subtotal: data.subtotal || 0,
      discountAmount: data.discountAmount || 0,
      discountDetail: data.discountDetail || '',
      totalPrice: data.totalPrice || 0
    };
  };

  useEffect(() => {
    fetchSales('pedidos', setOrderSales, transformOrderData);
    fetchSales('compras_mercadopago', setMercadoPagoSales, transformMercadoPagoData);
    fetchSales('ventas_fisicas', setPhysicalSales, transformPhysicalData);
  }, [fetchSales]);

  const toggleSaleDetails = (id, type) => {
    setSelectedSale(prevSelected =>
      prevSelected.id === id && prevSelected.type === type
        ? { id: null, type: null }
        : { id, type }
    );
  };

  const handleLoadMore = () => {
    setItemsToShow(prevItemsToShow => prevItemsToShow + ITEMS_PER_PAGE);
  };

  const handleCancelSale = async (saleId, saleType, cartItems) => {
    try {
      const confirmed = window.confirm('¿Estás seguro que deseas cancelar esta venta?');
      if (!confirmed) return;
  
      const collectionNames = {
        order: 'pedidos',
        mercadoPago: 'compras_mercadopago',
        physical: 'ventas_fisicas'
      };
      const collectionName = collectionNames[saleType];
      if (!collectionName) return;

      const saleRef = doc(db, collectionName, saleId);

      await runTransaction(db, async (transaction) => {
        const saleDoc = await transaction.get(saleRef);

        if (!saleDoc.exists) {
          throw new Error("Document does not exist!");
        }

        const productRefs = cartItems.map(item => doc(db, 'products', item.id));
        const productDocs = await Promise.all(productRefs.map(ref => transaction.get(ref)));

        productDocs.forEach((productDoc, index) => {
          if (!productDoc.exists) {
            throw new Error(`Product ${cartItems[index].id} does not exist!`);
          }
        });

        productDocs.forEach((productDoc, index) => {
          const newStock = productDoc.data().stock + cartItems[index].quantity;
          transaction.update(productRefs[index], { stock: newStock });
        });

        transaction.update(saleRef, { isConfirmed: false });
      });

      setNotification({ message: 'Venta cancelada y stock repuesto.', show: true });
      fetchSales(collectionName, saleType === 'order' ? setOrderSales : saleType === 'mercadoPago' ? setMercadoPagoSales : setPhysicalSales, saleType === 'order' ? transformOrderData : saleType === 'mercadoPago' ? transformMercadoPagoData : transformPhysicalData);
    } catch (error) {
      console.error("Error canceling sale:", error);
      setNotification({ message: 'Error al cancelar la venta.', show: true });
    }
  };

  const normalizeStartDate = (date) => {
    const normalizedDate = new Date(date);
    normalizedDate.setHours(23, 59, 59, 999);
    return normalizedDate;
  };

  const normalizeEndDate = (date) => {
    const normalizedDate = new Date(date);
    normalizedDate.setHours(23, 59, 59, 999);
    return normalizedDate;
  };

  const isDateInRange = (date, start, end) => {
    if (!date) return false;

    const saleDate = date.toDate();
    const startDate = normalizeStartDate(start);
    const endDate = normalizeEndDate(end);

    return (saleDate >= startDate && saleDate <= endDate);
  };

  const filteredSales = {
    order: orderSales.filter(sale =>
      sale.orderNumber.toString().includes(searchOrder) &&
      sale.customerName.toLowerCase().includes(searchName.toLowerCase()) &&
      isDateInRange(sale.date, startDate, endDate)
    ),
    mercadoPago: mercadoPagoSales.filter(sale =>
      sale.orderNumber.toString().includes(searchOrder) &&
      sale.customerName.toLowerCase().includes(searchName.toLowerCase()) &&
      isDateInRange(sale.date, startDate, endDate)
    ),
    physical: physicalSales.filter(sale =>
      sale.orderNumber.toString().includes(searchOrder) &&
      sale.customerName.toLowerCase().includes(searchName.toLowerCase()) &&
      isDateInRange(sale.date, startDate, endDate)
    )
  };

  const displayedSales = filteredSales[activeTab].slice(0, itemsToShow);

  if (loading) return <Spinner />;

  return (
    <div className="renamed-sales-report">
      <h2>Informe de Ventas</h2>
      <Notification message={notification.message} show={notification.show} />

      <div className="renamed-tabs">
        {['order', 'mercadoPago', 'physical'].map(type => (
          <button
            key={type}
            className={`tab-button ${activeTab === type ? 'active' : ''}`}
            onClick={() => setActiveTab(type)}
          >
            {type === 'order' ? 'Ventas por Pedido' : type === 'mercadoPago' ? 'Ventas con MercadoPago' : 'Ventas Físicas'}
          </button>
        ))}
      </div>

      <div className="renamed-filters">
        <input
          type="text"
          placeholder="Buscar por Número"
          value={searchOrder}
          onChange={(e) => setSearchOrder(e.target.value)}
          className="renamed-filter-input"
        />
        <input
          type="text"
          placeholder="Buscar por Nombre"
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
          className="renamed-filter-input"
        />
        <input
          type="date"
          value={startDate.toISOString().split('T')[0]}
          onChange={(e) => setStartDate(new Date(e.target.value))}
          className="renamed-filter-input"
        />
        <input
          type="date"
          value={endDate.toISOString().split('T')[0]}
          onChange={(e) => setEndDate(new Date(e.target.value))}
          className="renamed-filter-input"
        />
      </div>

      <div className="renamed-sales-section">
        <h3>{activeTab === 'order' ? 'Ventas por Pedido' : activeTab === 'mercadoPago' ? 'Ventas con MercadoPago' : 'Ventas Físicas'}</h3>
        <ul>
          {displayedSales.map((sale) => (
            <li key={sale.id} className={sale.isConfirmed ? '' : 'cancelled-sale'}>
              <div className="renamed-sale-summary">
                <span>{activeTab === 'order' ? 'Pedido N°: ' : 'Venta N°: '}{sale.orderNumber}</span>
                <span>Fecha: {sale.date.toDate().toLocaleString()}</span>
                <span>Total: ${formatNumber(sale.totalPrice)}</span>
                <button onClick={() => toggleSaleDetails(sale.id, activeTab)} className="renamed-details-button">
                  {selectedSale.id === sale.id && selectedSale.type === activeTab ? 'Ocultar Detalles' : 'Ver Detalles'}
                </button>
                {sale.isConfirmed ? (
                  <button onClick={() => handleCancelSale(sale.id, activeTab, sale.cartItems)} className="cancel-sale-button">
                    Cancelar Venta
                  </button>
                ) : (
                  <button className="cancelled-sale-button" disabled>
                    {activeTab === 'order' ? 'Sin confirmar' : 'Venta no computada'}
                  </button>
                )}
              </div>
              {selectedSale.id === sale.id && selectedSale.type === activeTab && (
                <div className="renamed-sale-details">
                  <h4>Detalles de la {activeTab === 'order' ? 'Pedido' : 'Venta'}</h4>
                  <p>Cliente: {sale.customerName}</p>
                  <p>Email: {sale.email}</p>
                  <p>Teléfono: {sale.phone}</p>
                  <p>Dirección: {sale.address}</p>
                  {activeTab === 'physical' && (
                    <>
                      <p>CUIT: {sale.cuit}</p>
                      <p>Tipo de Factura: {sale.invoiceType}</p>
                      <p>Información Adicional: {sale.additionalInfo}</p>
                    </>
                  )}
                  <p>Productos:</p>
                  <ul>
                    {sale.cartItems.map((product, i) => (
                      <li key={i} className="renamed-product-item">
                        <p>Cantidad: {product.quantity}</p>
                        <p>Articulo: {product.model}</p>
                        <p>Precio: ${formatNumber(product.price)}</p>
                        {product.image && <img src={product.image} alt={product.name} className="renamed-product-image" />}
                        <hr className="renamed-product-separator" />
                      </li>
                    ))}
                  </ul>
                  <p>Subtotal: ${formatNumber(sale.subtotal)}</p>
                  {sale.discountAmount > 0 && (
                    <>
                      <p>Descuento: ${formatNumber(sale.discountAmount)}</p>
                      <p>Tipo de descuento: {sale.discountDetail}</p>
                    </>
                  )}
                  <p>Total: ${formatNumber(sale.totalPrice)}</p>
                </div>
              )}
            </li>
          ))}
        </ul>
        {itemsToShow < filteredSales[activeTab].length && (
          <button onClick={handleLoadMore} className="load-more-button">Cargar más</button>
        )}
      </div>
    </div>
  );
};

export default RenamedHistorySales;
