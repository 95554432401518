import React, { useEffect, useState } from 'react';
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const ProductDetailModal = ({ product, onClose }) => {
  const [imageURLs, setImageURLs] = useState([]);

  useEffect(() => {
    const fetchImageURLs = async () => {
      const storage = getStorage();
      const urls = await Promise.all(product.images.map(async (imagePath) => {
        const imageRef = ref(storage, imagePath);
        return getDownloadURL(imageRef);
      }));
      setImageURLs(urls);
    };

    fetchImageURLs();
  }, [product.images]);

  const formatNumber = (number) => {
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.', ',').replace(/,/g, '.').replace(/\.$/, ',00');
  };

  return (
    <div className="modal-overlay-container">
      <div className="modal-content-container product-detail-modal">
        <h3>{product.model}</h3>
        {imageURLs.map((url, index) => (
          <img key={index} src={url} alt={product.model} className="product-detail-image" />
        ))}
        <p><strong>Precio:</strong> ${formatNumber(product.price)}</p>
        <p><strong>Categoría:</strong> {product.category}</p>
        <p><strong>Descripción:</strong> {product.description}</p>
        <button onClick={onClose}>Cerrar</button>
      </div>
    </div>
  );
};

export default ProductDetailModal;
