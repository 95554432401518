import React, { useState, useEffect } from 'react';
import { pinFileToIPFS } from '../pinata';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import './History.css';
import Spinner from './Spinner';

const History = () => {
  const [title, setTitle] = useState('Historia de la Empresa');
  const [content, setContent] = useState([{ type: 'text', value: 'Nuestra historia...' }]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const isAdminUser = localStorage.getItem('isAdmin') === 'true';
    setIsAdmin(isAdminUser);

    const fetchHistoryInfo = async () => {
      setIsLoading(true);
      try {
        const docRef = doc(db, 'historyInfo', 'main');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setTitle(data.title || 'Historia de la Empresa');
          setContent(data.content || []);
        }
      } catch (error) {
        console.error('Error fetching history info:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchHistoryInfo();
  }, []);

  const handleImageUpload = async (index, e) => {
    const file = e.target.files[0];
    if (file) {
      setIsLoading(true);
      try {
        const imageUrl = await pinFileToIPFS(file);
        const newContent = [...content];
        newContent[index] = { type: 'image', value: imageUrl };
        setContent(newContent);
      } catch (error) {
        console.error('Error uploading image:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleAddContent = (type) => {
    setContent([...content, { type, value: '' }]);
  };

  const handleContentChange = (index, value) => {
    const newContent = [...content];
    newContent[index].value = value;
    setContent(newContent);
  };

  const handleRemoveContent = (index) => {
    const newContent = content.filter((_, i) => i !== index);
    setContent(newContent);
  };

  const handleSaveChanges = async () => {
    setIsLoading(true);
    try {
      await setDoc(doc(db, 'historyInfo', 'main'), {
        title,
        content
      });
      alert('Cambios guardados correctamente.');
    } catch (error) {
      console.error('Error saving changes:', error);
      alert('Error al guardar los cambios, por favor intente nuevamente.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="history">
      {isAdmin ? (
        <div className="admin-title">
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="title-input"
          />
        </div>
      ) : (
        <h2>{title}</h2>
      )}
      <div className="history-content">
        {content.map((item, index) => (
          <div key={index} className="history-item">
            {isAdmin && (
              <button className="remove-button" onClick={() => handleRemoveContent(index)}>Eliminar</button>
            )}
            {item.type === 'text' ? (
              isAdmin ? (
                <textarea
                  value={item.value}
                  onChange={(e) => handleContentChange(index, e.target.value)}
                  className="text-area"
                />
              ) : (
                <p className="history-paragraph">{item.value}</p>
              )
            ) : (
              <div className="history-image">
                {isAdmin && !item.value && (
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageUpload(index, e)}
                    className="file-input"
                  />
                )}
                {item.value && (
                  <img
                    src={item.value}
                    alt="Company"
                    className="company-image"
                  />
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      {isAdmin && (
        <div className="admin-controls">
          <button onClick={() => handleAddContent('text')}>Agregar Texto</button>
          <button onClick={() => handleAddContent('image')}>Agregar Imagen</button>
          <button className="save-button" onClick={handleSaveChanges}>Guardar todo</button>
        </div>
      )}
    </div>
  );
};

export default History;