
import axios from 'axios';

const PINATA_API_KEY = '99f41a9030e1edffe1ad';
const PINATA_SECRET_API_KEY = 'dc869744072fe1071c98786acc6609eb6346c91560146db90556146bc9206ca3';
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // en milisegundos

const pinFileToIPFS = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('pinataOptions', JSON.stringify({ cidVersion: 1 }));
  formData.append('pinataMetadata', JSON.stringify({ name: file.name }));

  for (let attempt = 1; attempt <= MAX_RETRIES; attempt++) {
    try {
      const response = await axios.post('https://api.pinata.cloud/pinning/pinFileToIPFS', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          pinata_api_key: PINATA_API_KEY,
          pinata_secret_api_key: PINATA_SECRET_API_KEY,
        }
      });
      return `https://gateway.pinata.cloud/ipfs/${response.data.IpfsHash}`;
    } catch (error) {
      console.error(`Error uploading image to Pinata (attempt ${attempt}):`, error.response ? error.response.data : error.message);
      if (attempt < MAX_RETRIES) {
        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      } else {
        throw new Error('Error uploading image, please try again.');
      }
    }
  }
};

export { pinFileToIPFS };
