import React from 'react';
import './PaginasMP.css';

const Failure = () => {
  return (
    <div className="failure">
      <h1>Pago Fallido</h1>
      <p>Hubo un problema al procesar tu pago. Por favor, intenta nuevamente.</p>
      <p>Puedes cerrar esta ventana.</p>
    </div>
  );
};

export default Failure;
