import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { addDoc, collection, getDocs, doc, getDoc, runTransaction } from 'firebase/firestore';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import './Cart.css';
import './Receipt.css';
import Spinner from './Spinner';
import Notifications from './Notification';
import 'jspdf-autotable';
import emailjs from 'emailjs-com';
import mercadopagoLogo from './../Logos/mercadopago.png';

const Cart = ({ cart, removeFromCart, clearCart, updateCartItemQuantity }) => {
  const [showForm, setShowForm] = useState(false);
  const [showCheckoutForm, setShowCheckoutForm] = useState(false);
  const [notification, setNotification] = useState({ message: '', show: false });
  const [formData, setFormData] = useState({
    nombre: '', telefono: '', email: '', domicilio: '', localidad: '',
    codigoPostal: '', provincia: '', pais: '', informacionAdicional: ''
  });
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoading, setIsLoading] = useState(false);
  const [discounts, setDiscounts] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [appliedDiscount, setAppliedDiscount] = useState(null);
  const [showDiscounts, setShowDiscounts] = useState(false);
  const [shippingCost, setShippingCost] = useState(0);

  useEffect(() => {
    const fetchDiscounts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'discounts'));
        const fetchedDiscounts = querySnapshot.docs.map(doc => doc.data());
        setDiscounts(fetchedDiscounts);
      } catch (error) {
        console.error("Error fetching discounts:", error);
      }
    };
    fetchDiscounts();
  }, []);

  useEffect(() => {
    const fetchShippingCosts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'shippingCosts'));
        const fetchedShippingCosts = querySnapshot.docs.map(doc => doc.data());
        calculateShippingCost(cart, fetchedShippingCosts);
      } catch (error) {
        console.error("Error fetching shipping costs:", error);
      }
    };

    fetchShippingCosts();
  }, [cart]);

  useEffect(() => {
    const handleReCaptchaVerify = async () => {
      if (executeRecaptcha) {
        try {
          const token = await executeRecaptcha('submit_order');
          setRecaptchaToken(token);
        } catch (error) {
          console.error("Error verifying reCAPTCHA:", error);
          showNotification('Error de reCAPTCHA, por favor intente nuevamente.');
        }
      }
    };

    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  const formatNumber = (price) => {
    return price
      .toFixed(2) // Mantiene dos decimales
      .replace('.', ',') // Reemplaza el punto decimal por una coma
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agrega puntos como separadores de miles
  };

  const calculateShippingCost = (cart, shippingCosts) => {
    const totalQuantity = cart.reduce((sum, item) => sum + item.quantity, 0);
    const applicableShippingCost = shippingCosts
      .filter(cost => totalQuantity >= cost.minQuantity)
      .sort((a, b) => b.minQuantity - a.minQuantity)[0];

    setShippingCost(applicableShippingCost ? applicableShippingCost.shippingCost : 0);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const showNotification = (message) => {
    setNotification({ message, show: true });
    setTimeout(() => setNotification({ message: '', show: false }), 3000);
  };

  const checkStockAvailability = async () => {
    for (const item of cart) {
      const productRef = doc(db, 'products', item.id);
      const productDoc = await getDoc(productRef);
      const productData = productDoc.data();
      if (!productData || productData.stock < item.quantity) {
        return false;
      }
    }
    return true;
  };

  const getOrderNumber = async (type) => {
    const counterRef = doc(db, 'counters', type === 'sale' ? 'saleCounter' : 'orderCounter');
    try {
      const newOrderNumber = await runTransaction(db, async (transaction) => {
        const counterDoc = await transaction.get(counterRef);
        if (!counterDoc.exists()) {
          throw new Error(`${type === 'sale' ? 'Sale' : 'Order'} counter document does not exist!`);
        }
        const newOrderNumber = counterDoc.data().currentOrderNumber + 1;
        transaction.update(counterRef, { currentOrderNumber: newOrderNumber });
        return newOrderNumber;
      });
      return newOrderNumber;
    } catch (error) {
      console.error("Transaction failed: ", error);
      showNotification(`Error generating ${type === 'sale' ? 'sale' : 'order'} number, please try again.`);
      return null;
    }
  };

  const sendEmail = (templateId, variables, serviceId) => {
    return emailjs.send(
      serviceId,
      templateId,
      variables,
      process.env.REACT_APP_EMAILJS_USER_ID
    )
      .then(response => {
        console.log('Email sent successfully!', response.status, response.text);
      })
      .catch(err => {
        console.error('Error sending email:', err);
        throw err;
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!recaptchaToken) {
      showNotification('Error de reCAPTCHA, por favor intente nuevamente.');
      setIsLoading(false);
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      showNotification('Dirección de correo electrónico no válida.');
      setIsLoading(false);
      return;
    }

    const isStockAvailable = await checkStockAvailability();
    if (!isStockAvailable) {
      showNotification('No hay inventario disponible, elimine items del carrito agotados.');
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch('https://us-central1-priball.cloudfunctions.net/verifyReCaptcha', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: recaptchaToken }),
      });

      const result = await response.json();
      console.log("reCAPTCHA verification result:", result);

      if (result.success) {
        const orderNumber = await getOrderNumber('order');
        if (!orderNumber) {
          setIsLoading(false);
          return;
        }

        const { subtotal, discountAmount, discountDetail, finalPrice } = calculateTotalPrice();

        const orderData = {
          orderNumber,
          ...formData,
          cartItems: cart,
          subtotal,
          discountAmount,
          discountDetail,
          shippingCost,
          totalPrice: finalPrice,
          date: new Date(),
          status: 'Pendiente',
        };

        await addDoc(collection(db, 'pedidos'), { ...orderData, isActive: true });

        const cartItemsHtml = `<ul>${orderData.cartItems.map(item =>
          `<li>${item.model} - Cantidad: ${item.quantity} - Precio: $${formatNumber(item.price)}</li>`
        ).join('')}</ul>`;

        const emailVariablesCustomer = {
          to_name: orderData.nombre,
          order_number: orderData.orderNumber,
          order_date: orderData.date,
          customer_name: orderData.nombre,
          customer_phone: orderData.telefono,
          customer_email: orderData.email,
          customer_address: orderData.domicilio,
          customer_city: orderData.localidad,
          customer_zip: orderData.codigoPostal,
          customer_state: orderData.provincia,
          customer_country: orderData.pais,
          cart_items: cartItemsHtml,
          subtotal: formatNumber(orderData.subtotal),
          discount: formatNumber(orderData.discountAmount),
          discountDetail: orderData.discountDetail,
          shippingCost: formatNumber(orderData.shippingCost),
          total: formatNumber(orderData.totalPrice),
          to_email: orderData.email,
          recipient: orderData.email
        };

        const emailVariablesAdmin = {
          ...emailVariablesCustomer,
          additional_info: orderData.informacionAdicional,
          to_email: process.env.REACT_APP_ADMIN_EMAIL,
          recipient: process.env.REACT_APP_ADMIN_EMAIL
        };

        try {
          await sendEmail(process.env.REACT_APP_EMAILJS_TEMPLATE_ID_CUSTOMER_P, emailVariablesCustomer, process.env.REACT_APP_EMAILJS_SERVICE_ID);
          await sendEmail(process.env.REACT_APP_EMAILJS_TEMPLATE_ID_ADMIN_P, emailVariablesAdmin, process.env.REACT_APP_EMAILJS_SERVICE_ID);
          showNotification("Pedido enviado exitosamente!");
          setShowForm(false);
          setFormData({
            nombre: '', telefono: '', email: '', domicilio: '', localidad: '',
            codigoPostal: '', provincia: '', pais: '', informacionAdicional: '',
          });
          clearCart();
          setKeyword('');
          setAppliedDiscount(null);
        } catch (error) {
          console.error('Failed to send email:', error);
          showNotification('Error al enviar el correo. Por favor, contacte al administrador.');
        }
      } else {
        showNotification("Error de reCAPTCHA, por favor intente nuevamente.");
      }
    } catch (error) {
      console.error("Error al enviar el pedido:", error);
      showNotification("Error al enviar el pedido, por favor intente nuevamente.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckoutPro = async () => {
    setIsLoading(true);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      showNotification('Dirección de correo electrónico no válida.');
      setIsLoading(false);
      return;
    }

    if (!formData.nombre || !formData.email || !formData.telefono || !formData.domicilio) {
      showNotification('Por favor, complete la información de envío antes de proceder al pago.');
      setIsLoading(false);
      return;
    }

    const isStockAvailable = await checkStockAvailability();
    if (!isStockAvailable) {
      showNotification('No hay inventario disponible, elimine items del carrito agotados.');
      setIsLoading(false);
      return;
    }

    const { subtotal, discountAmount, discountDetail, finalPrice } = calculateTotalPrice();

    const items = cart.map(item => ({
      title: item.model,
      unit_price: Number(item.price * (1 - discountAmount / subtotal)),
      quantity: Number(item.quantity),
      id: item.id,
    }));

    // Agregar el costo de envío como un ítem separado
    items.push({
      title: 'Costo de Envío',
      unit_price: Number(shippingCost),
      quantity: 1,
      id: 'shipping_cost',
    });

    const orderNumber = await getOrderNumber('sale');
    if (!orderNumber) {
      setIsLoading(false);
      return;
    }

    const orderData = {
      orderNumber,
      ...formData,
      cartItems: cart,
      subtotal,
      discountAmount,
      discountDetail,
      shippingCost,
      totalPrice: finalPrice,
      date: new Date(),
      status: 'Procesando',
      isPaid: false
    };

    try {
      console.log('Creating order document in Firestore:', orderData);
      const orderRef = await addDoc(collection(db, 'compras_mercadopago'), { ...orderData, isActive: true });
      console.log('Order document created:', orderRef.id);

      const response = await fetch('https://us-central1-priball.cloudfunctions.net/createPreference', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          items,
          payer: {
            name: formData.nombre,
            email: formData.email,
            phone: {
              area_code: '',
              number: formData.telefono,
            },
            address: {
              zip_code: formData.codigoPostal,
              street_name: formData.domicilio,
              street_number: '',
            }
          },
          external_reference: orderRef.id,
          total_amount: finalPrice + shippingCost, // Agregar el costo de envío al total
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.init_point) {
        const cartItemsHtml = `<ul>${orderData.cartItems.map(item =>
          `<li>${item.model} - Cantidad: ${item.quantity} - Precio: $${formatNumber(item.price)}</li>`
        ).join('')}</ul>`;

        const emailVariablesCustomer = {
          to_name: orderData.nombre,
          order_number: orderData.orderNumber,
          order_date: orderData.date,
          customer_name: orderData.nombre,
          customer_phone: orderData.telefono,
          customer_email: orderData.email,
          customer_address: orderData.domicilio,
          customer_city: orderData.localidad,
          customer_zip: orderData.codigoPostal,
          customer_state: orderData.provincia,
          customer_country: orderData.pais,
          cart_items: cartItemsHtml,
          subtotal: formatNumber(orderData.subtotal),
          discount: formatNumber(orderData.discountAmount),
          discountDetail: orderData.discountDetail,
          shippingCost: formatNumber(orderData.shippingCost),
          total: formatNumber(orderData.totalPrice),
          to_email: orderData.email,
          recipient: orderData.email
        };

        const emailVariablesAdmin = {
          ...emailVariablesCustomer,
          to_email: process.env.REACT_APP_ADMIN_EMAIL
        };

        try {
          await sendEmail(process.env.REACT_APP_EMAILJS_TEMPLATE_ID_CUSTOMER_MP, emailVariablesCustomer, process.env.REACT_APP_EMAILJS_SERVICE_ID);
          await sendEmail(process.env.REACT_APP_EMAILJS_TEMPLATE_ID_ADMIN_MP, emailVariablesAdmin, process.env.REACT_APP_EMAILJS_SERVICE_ID);
          clearCart();
          setKeyword('');
          setAppliedDiscount(null);
          window.location.href = data.init_point;
        } catch (error) {
          if (error.status === 426) {
            showNotification('Se alcanzó la cuota de correos electrónicos, por favor intente más tarde.');
          } else {
            console.error('Failed to send email:', error);
            showNotification('Error al enviar el correo. La orden se ha creado, pero por favor contacte al administrador.');
          }
        }
      } else {
        showNotification('Error al crear la preferencia de pago, por favor intente nuevamente.');
      }
    } catch (error) {
      console.error('Error al crear la preferencia de pago:', error);
      showNotification('Error al crear la preferencia de pago, por favor intente nuevamente.');
    } finally {
      setIsLoading(false);
    }
  };

  const calculateTotalPrice = () => {
    const totalQuantity = cart.reduce((sum, item) => sum + item.quantity, 0);
    const subtotal = cart.reduce((sum, item) => sum + item.price * item.quantity, 0);

    let discountAmount = 0;
    let discountDetail = '';

    if (appliedDiscount && appliedDiscount.type === 'keyword') {
      discountAmount = subtotal * (appliedDiscount.percentage / 100);
      discountDetail = `Descuento aplicado (${appliedDiscount.percentage}%) por palabra clave`;
    } else {
      discounts.forEach(discount => {
        if (discount.type === 'quantity' && totalQuantity >= discount.minQuantity) {
          const currentDiscount = subtotal * (discount.percentage / 100);
          if (currentDiscount > discountAmount) {
            discountAmount = currentDiscount;
            discountDetail = `Descuento aplicado (${discount.percentage}%) por cantidad mínima`;
          }
        } else if (discount.type === 'amount' && subtotal >= discount.minAmount) {
          const currentDiscount = subtotal * (discount.percentage / 100);
          if (currentDiscount > discountAmount) {
            discountAmount = currentDiscount;
            discountDetail = `Descuento aplicado (${discount.percentage}%) por monto mínimo`;
          }
        }
      });
    }

    const finalPrice = subtotal - discountAmount + shippingCost; // Sumar el costo de envío al precio final

    return { subtotal, discountAmount, discountDetail, shippingCost, finalPrice };
  };

  const handleApplyDiscount = () => {
    const foundDiscount = discounts.find(discount => discount.type === 'keyword' && discount.keyword === keyword);
    if (foundDiscount) {
      setAppliedDiscount(foundDiscount);
      showNotification(`Descuento del ${foundDiscount.percentage}% aplicado!`);
    } else {
      showNotification('Código de descuento no válido.');
    }
  };

  const toggleForm = (formType) => {
    if (formType === 'order') {
      setShowForm(!showForm);
      setShowCheckoutForm(false);
    } else if (formType === 'checkout') {
      setShowCheckoutForm(!showCheckoutForm);
      setShowForm(false);
    }
  };

  const handleQuantityChange = (e, itemId) => {
    const newQuantity = parseInt(e.target.value);
    updateCartItemQuantity(itemId, newQuantity);
  };

  const { subtotal, discountAmount, discountDetail, finalPrice } = calculateTotalPrice();

  return (
    <div className="cart">
      <Notifications message={notification.message} show={notification.show} />
      {isLoading && <Spinner />}
      <h2>Carrito de Compras</h2>
      {cart.length === 0 ? (
        <p>El carrito está vacío.</p>
      ) : (
        <div>
          {cart.map(item => (
            <div key={item.id} className="cart-item">
              <img src={item.images ? item.images[0] : ''} alt={item.model} className="cart-item-image" />
              <div className="cart-item-details">
                <span>{item.model}</span>
                <span>
                  Cantidad:
                  <input
                    type="number"
                    value={item.quantity}
                    min="1"
                    onChange={(e) => handleQuantityChange(e, item.id)}
                    className="quantity-input"
                  />
                </span>
                <span>Precio: ${formatNumber(item.price)}</span>
                <span>Total: ${formatNumber(item.price * item.quantity)}</span>
              </div>
              <button className='local-sales-remove-btn ' onClick={() => removeFromCart(item.id)}>Eliminar</button>
            </div>
          ))}
          <div className="cart-total">
            <h3>Subtotal: ${formatNumber(subtotal)}</h3>
            {discountDetail && <h4>{discountDetail}: -${formatNumber(discountAmount)}</h4>}
            <h4>Costo de Envío: ${formatNumber(shippingCost)}</h4>
            <h3>Total con envío: ${formatNumber(finalPrice)}</h3>
            <div className="discount-code">
              <input
                type="text"
                placeholder="Código de descuento"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                className="discount-input"
              />
              <br />
              <button onClick={handleApplyDiscount} className="view-toggle-btn">
                Aplicar Descuento
              </button>
            </div>
            <div className="cart-buttons">
              <button className="cart-button" onClick={() => toggleForm('order')}>
                {showForm ? 'Ocultar Formulario' : 'Enviar Pedido'}
              </button>
              <button className="cart-button" onClick={() => toggleForm('checkout')}>
                {showCheckoutForm ? 'Ocultar Formulario ' : 'Comprar con MercadoPago '}
                <img src={mercadopagoLogo} alt="MercadoPago Logo" className="mercadopago-logo" />
              </button>
            </div>
          </div>
        </div>
      )}
      {showForm && (
        <div className="order-form">
          <h3>Información para solicitud de pedido</h3>
          <form onSubmit={handleSubmit}>
            <input type="text" name="nombre" placeholder="Nombre" value={formData.nombre} onChange={handleChange} maxLength={50} required />
            <input type="text" name="telefono" placeholder="Teléfono" value={formData.telefono} onChange={handleChange} maxLength={20} required />
            <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} maxLength={50} required />
            <input type="text" name="domicilio" placeholder="Domicilio" value={formData.domicilio} onChange={handleChange} maxLength={100} required />
            <input type="text" name="localidad" placeholder="Localidad" value={formData.localidad} onChange={handleChange} maxLength={50} required />
            <input type="text" name="codigoPostal" placeholder="Código Postal" value={formData.codigoPostal} onChange={handleChange} maxLength={10} required />
            <input type="text" name="provincia" placeholder="Provincia" value={formData.provincia} onChange={handleChange} maxLength={50} required />
            <input type="text" name="pais" placeholder="País" value={formData.pais} onChange={handleChange} maxLength={50} required />
            <br />
            <textarea maxLength={300} name="informacionAdicional" placeholder="Información Adicional" value={formData.informacionAdicional} onChange={handleChange}></textarea>
            <br />
            <button type="submit" className="cart-button" disabled={!recaptchaToken || isLoading}>
              {isLoading ? 'Enviando...' : (recaptchaToken ? 'Enviar Pedido' : 'Verificando reCAPTCHA...')}
            </button>
          </form>
        </div>
      )}
      {showCheckoutForm && (
        <div className="order-form">
          <h3>Información para comprar con MercadoPago</h3>
          <form>
            <input type="text" name="nombre" placeholder="Nombre" value={formData.nombre} onChange={handleChange} maxLength={50} required />
            <input type="text" name="telefono" placeholder="Teléfono" value={formData.telefono} onChange={handleChange} maxLength={20} required />
            <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} maxLength={50} required />
            <input type="text" name="domicilio" placeholder="Domicilio" value={formData.domicilio} onChange={handleChange} maxLength={100} required />
            <input type="text" name="localidad" placeholder="Localidad" value={formData.localidad} onChange={handleChange} maxLength={50} required />
            <input type="text" name="codigoPostal" placeholder="Código Postal" value={formData.codigoPostal} onChange={handleChange} maxLength={10} required />
            <input type="text" name="provincia" placeholder="Provincia" value={formData.provincia} onChange={handleChange} maxLength={50} required />
            <input type="text" name="pais" placeholder="País" value={formData.pais} onChange={handleChange} maxLength={50} required />
            <br />
            <textarea maxLength={300} name="informacionAdicional" placeholder="Información Adicional" value={formData.informacionAdicional} onChange={handleChange}></textarea>
            <br />
            <button type="button" className="view-toggle-btn" onClick={handleCheckoutPro} disabled={isLoading}>
              {isLoading ? 'Procesando...' : (
                <>
                  <img src={mercadopagoLogo} alt="MercadoPago Logo" className="mercadopago-logo" />
                  Continuar compra
                </>
              )}
            </button>
          </form>
        </div>
      )}
      <br />
      <button className="discount-button" onClick={() => setShowDiscounts(!showDiscounts)}>
        {showDiscounts ? 'Ocultar Descuentos Disponibles' : 'Mostrar Descuentos Disponibles'}
      </button>

      {showDiscounts && (
        <div className="discount-list">
          <h3 className="discount-list-title">Descuentos Disponibles</h3>
          {discounts.map(discount => (
            <div key={discount.id} className="discount-item">
              {discount.minQuantity && discount.minQuantity !== 'N/A' && (
                <span className="discount-detail">Cantidad mínima: {discount.minQuantity} unidades</span>
              )}
              {discount.minAmount && discount.minAmount !== 'N/A' && (
                <span className="discount-detail">Monto mínimo: ${formatNumber(discount.minAmount)}</span>
              )}
              {discount.percentage && discount.percentage !== 'N/A' && (
                <span className="discount-detail">Descuento: -{discount.percentage}%</span>
              )}
              {discount.keyword && discount.keyword !== 'N/A' && (
                <span className="discount-detail">Con código de descuento</span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Cart;
