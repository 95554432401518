import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';
import logo from '../Logos/priballblanca.png';

const Navbar = ({ cart }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  const isAdmin = localStorage.getItem('isAdmin') === 'true';
  const menuRef = useRef(null);
  const hamburgerRef = useRef(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);

  const formatNumber = (number) => {
    if (typeof number !== 'number' || isNaN(number)) {
      return '0,00';
    }
    return number
      .toFixed(2) // Mantiene dos decimales
      .replace('.', ',') // Reemplaza el punto decimal por una coma
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agrega puntos como separadores de miles
  };

  useEffect(() => {
    const logoImage = document.querySelector('.logo img');
    if (logoImage) {
      logoImage.classList.add('loaded');
    }

    const handleClickOutside = (event) => {
      if (isOpen && 
          menuRef.current && 
          !menuRef.current.contains(event.target) &&
          !hamburgerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    const calculateCartTotals = () => {
      const subtotal = cart.reduce((sum, item) => sum + item.price * item.quantity, 0);
      const quantity = cart.reduce((sum, item) => sum + item.quantity, 0);

      let discountAmount = 0;
      cart.forEach(item => {
        if (item.discount) {
          discountAmount += (item.price * item.discount / 100) * item.quantity;
        }
      });

      const finalPrice = subtotal - discountAmount;
      setTotalPrice(finalPrice);
      setTotalQuantity(quantity);
    };

    calculateCartTotals();
  }, [cart]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('isAdmin');
    navigate('/login');
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Priball Logo" />
          </Link>
        </div>
        <ul className={`nav-links ${isOpen ? 'open' : ''}`} ref={menuRef}>
          <li><Link to="/historia" onClick={toggleMenu}>Historia</Link></li>
          <li><Link to="/empresa" onClick={toggleMenu}>Empresa</Link></li>
          <li><Link to="/contacto" onClick={toggleMenu}>Contacto</Link></li>
          <li><Link to="/productos" onClick={toggleMenu}>Productos</Link></li>
          <li>
            <Link to="/carrito" onClick={toggleMenu}>
              Carrito {totalQuantity > 0 && (
                <span className="cart-info">
                  ({totalQuantity}) ${formatNumber(totalPrice)}
                </span>
              )}
            </Link>
          </li>
          {isAdmin && (
            <li><Link to="/admin" onClick={toggleMenu}>Admin</Link></li>
          )}
          {!isAuthenticated ? (
            <li><Link to="/login" onClick={toggleMenu}>Login</Link></li>
          ) : (
            <li><button onClick={handleLogout}>Logout</button></li>
          )}
        </ul>
        <div className="hamburger" onClick={toggleMenu} ref={hamburgerRef}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
