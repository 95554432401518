import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import Spinner from './Spinner';
import './SubscriberList.css';

const SubscriberList = () => {
  const [subscribers, setSubscribers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSubscribers = async () => {
      setIsLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, 'Subscriptions'));
        const subscriberList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSubscribers(subscriberList);
      } catch (error) {
        console.error('Error fetching subscribers:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubscribers();
  }, []);

  const handleCopyEmails = () => {
    const emails = subscribers.map(subscriber => subscriber.email).join(', ');
    navigator.clipboard.writeText(emails).then(() => {
      alert('Emails copiados al portapapeles');
    }).catch(err => {
      console.error('Error al copiar los emails: ', err);
    });
  };

  const handleDeleteSubscriber = async (subscriberId) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar este suscriptor?')) {
      try {
        await deleteDoc(doc(db, 'Subscriptions', subscriberId));
        setSubscribers(subscribers.filter(subscriber => subscriber.id !== subscriberId));
        alert('Suscriptor eliminado exitosamente');
      } catch (error) {
        console.error('Error eliminando suscriptor: ', error);
        alert('Error al eliminar suscriptor');
      }
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="subscriber-list">
      <h2>Lista de Suscriptores</h2>
      <button onClick={handleCopyEmails} className="copy-button">Copiar Emails</button>
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Email</th>
              <th>Fecha de Creación</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {subscribers.map(subscriber => (
              <tr key={subscriber.id}>
                <td>{subscriber.email}</td>
                <td>{subscriber.timestamp ? new Date(subscriber.timestamp.seconds * 1000).toLocaleString() : 'Fecha no disponible'}</td>
                <td>
                  <button
                    onClick={() => handleDeleteSubscriber(subscriber.id)}
                    className="delete-button"
                    title="Eliminar suscriptor"
                  >
                    🗑️
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SubscriberList;
