import React, { useState, useEffect } from 'react';
import './InvoiceGenerator.css'; // Importar el archivo CSS

const InvoiceGenerator = () => {
  const [invoiceType, setInvoiceType] = useState('C'); // Predeterminado a Factura C
  const [concept, setConcept] = useState(1); // Predeterminado a Productos
  const [pointsOfSale, setPointsOfSale] = useState([]); // Lista de puntos de venta
  const [selectedPointOfSale, setSelectedPointOfSale] = useState(''); // Punto de venta seleccionado
  const [customerData, setCustomerData] = useState({
    name: '',  // Opcional
    cuit: '',  // Opcional
    dni: '',   // Opcional
    address: '', // Opcional
  });
  const [products, setProducts] = useState([]);
  const [productModel, setProductModel] = useState(''); // Obligatorio
  const [productPrice, setProductPrice] = useState(''); // Obligatorio
  const [productQuantity, setProductQuantity] = useState(''); // Obligatorio
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  // Obtener puntos de venta al cargar el componente
  useEffect(() => {
    const fetchPointsOfSale = async () => {
      try {
        const response = await fetch('https://us-central1-priball.cloudfunctions.net/getPointsOfSale');
        if (!response.ok) {
          throw new Error('Error al obtener los puntos de venta');
        }
        const data = await response.json();
        setPointsOfSale(data);
        setSelectedPointOfSale(data[0].numero); // Seleccionar el primer punto de venta por defecto
      } catch (err) {
        setError('Hubo un problema al cargar los puntos de venta.');
      }
    };
    fetchPointsOfSale();
  }, []);

  const handleAddProduct = () => {
    if (productModel && productPrice && productQuantity) {
      const newProduct = {
        model: productModel,
        price: parseFloat(productPrice),
        quantity: parseInt(productQuantity, 10),
      };
      setProducts([...products, newProduct]);
      setProductModel('');
      setProductPrice('');
      setProductQuantity('');
    } else {
      setError('Todos los campos de producto son obligatorios');
    }
  };

  const handleCUITOrDNIChange = async (identifier) => {
    setCustomerData({ ...customerData, cuit: identifier, dni: identifier });

    if (identifier.length === 11 || identifier.length === 8) { // CUIT tiene 11 dígitos, DNI tiene 8
      try {
        const response = await fetch('https://us-central1-priball.cloudfunctions.net/getCustomerData', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ cuit: identifier }),
        });

        if (!response.ok) {
          throw new Error('Error al cargar los datos del cliente');
        }

        const data = await response.json();
        setCustomerData((prevData) => ({
          ...prevData,
          name: data.name,
          address: data.address,
        }));
        setSuccessMessage('Datos del cliente cargados correctamente.');

      } catch (err) {
        setError('No se pudieron cargar los datos del cliente. Verificá el CUIT o DNI.');
      }
    }
  };

  const handleGenerateInvoice = async () => {
    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    if (products.length === 0) {
      setError('Debe agregar al menos un producto');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch('https://us-central1-priball.cloudfunctions.net/createInvoice', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          invoiceType,
          concept,
          selectedPointOfSale,
          customerData,
          products,
        }),
      });

      if (!response.ok) {
        throw new Error('Error al generar la factura');
      }

      const data = await response.json();
      setSuccessMessage(`Factura generada con éxito. CAE: ${data.CAE}, Vencimiento CAE: ${data.CAEFchVto}`);
    } catch (err) {
      setError('Hubo un error al generar la factura. Por favor, intentá de nuevo.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="invoice-generator">
      <h2 className="invoice-generator-title">Generar Factura {invoiceType}</h2>
      <div className="invoice-generator-section">
        <label htmlFor="invoiceType">Tipo de Factura:</label>
        <select id="invoiceType" value={invoiceType} onChange={(e) => setInvoiceType(e.target.value)} disabled>
          <option value="C">Factura C</option>
        </select>
      </div>

      <div className="invoice-generator-section">
        <label htmlFor="concept">Tipo de Concepto:</label>
        <select id="concept" value={concept} onChange={(e) => setConcept(parseInt(e.target.value, 10))}>
          <option value={1}>Productos</option>
          <option value={2}>Servicios</option>
          <option value={3}>Productos y Servicios</option>
        </select>
      </div>

      <div className="invoice-generator-section">
        <label htmlFor="pointOfSale">Punto de Venta:</label>
        <select id="pointOfSale" value={selectedPointOfSale} onChange={(e) => setSelectedPointOfSale(e.target.value)}>
          {pointsOfSale.map((pos) => (
            <option key={pos.numero} value={pos.numero}>
              {`Punto de Venta ${pos.numero} - ${pos.descripcion}`}
            </option>
          ))}
        </select>
      </div>

      <div className="invoice-generator-section">
        <h3>Información del Cliente (Opcional)</h3>
        <input
          type="text"
          placeholder="Nombre del Cliente"
          value={customerData.name}
          onChange={(e) => setCustomerData({ ...customerData, name: e.target.value })}
        />
        <input
          type="text"
          placeholder="CUIT/DNI (opcional)"
          value={customerData.cuit || customerData.dni}
          onChange={(e) => handleCUITOrDNIChange(e.target.value)}
        />
        <input
          type="text"
          placeholder="Dirección (opcional)"
          value={customerData.address}
          onChange={(e) => setCustomerData({ ...customerData, address: e.target.value })}
        />
      </div>

      <div className="invoice-generator-section">
        <h3>Productos</h3>
        <input
          type="text"
          placeholder="Modelo del Producto"
          value={productModel}
          onChange={(e) => setProductModel(e.target.value)}
        />
        <input
          type="number"
          placeholder="Precio"
          value={productPrice}
          onChange={(e) => setProductPrice(e.target.value)}
        />
        <input
          type="number"
          placeholder="Cantidad"
          value={productQuantity}
          onChange={(e) => setProductQuantity(e.target.value)}
        />
        <button onClick={handleAddProduct} className="invoice-generator-button">Agregar Producto</button>
      </div>

      <ul className="invoice-generator-product-list">
        {products.map((product, index) => (
          <li key={index} className="invoice-generator-product-item">
            {product.model} - ${product.price} x {product.quantity} unidades
          </li>
        ))}
      </ul>

      <button onClick={handleGenerateInvoice} disabled={loading} className="invoice-generator-button">
        {loading ? 'Generando...' : 'Generar Factura'}
      </button>

      {error && <p className="invoice-generator-error">{error}</p>}
      {successMessage && <p className="invoice-generator-success">{successMessage}</p>}
    </div>
  );
};

export default InvoiceGenerator;
